import axios from 'axios';
import { Fragment, Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import update from 'immutability-helper';

import { apiCall } from '../api-services/api';
import { locationApi } from '../api-services/api-list';
import { StateType } from '../components/LocationDashboard/helpers';
import { updateToken } from '../redux/actions/auth.actions';
import { UserDataType } from '../@types/api-types';
import { handleNotification } from '../utils/notification-handler';
import Header from '../components/LocationDashboard/Header';
import { getZuluFormatUTC, manageChartData, manageGaugeData } from '../utils';
import MapGauge from '../components/LocationDashboard/MapGauge';
import { FloorPlanMapDetailsType } from '../@types';
import { inputFieldFormElements } from '../components-shared/ChartInputFields/helpers';
import ChartInputFields from '../components-shared/ChartInputFields';
import Chart from '../components/LocationDashboard/Chart';
import { Col, Row } from 'antd';

type PropsType = PropsFromRedux &
  RouteComponentProps<
    { locationID: string },
    any,
    {
      mapDetails: FloorPlanMapDetailsType;
    }
  > & {
    userData: Partial<UserDataType>;
  };

class LocationDashboard extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  locationID: string | undefined;
  mapAutoUpdate: NodeJS.Timeout | undefined = undefined;

  constructor(props: PropsType) {
    super(props);

    this.locationID = props.match?.params?.locationID;

    this.state = {
      loadingHeader: true,
      loadingGauge: true,
      loadingChart: true,
      locationDetails: {},
      gaugeList: [],
      chartList: [],

      inputFieldElements: { ...inputFieldFormElements },
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchLocationDetails();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  fetchLocationDetails = async () => {
    let stateData: Partial<StateType> = { ...this.state };
    const { userData, updateToken } = this.props;

    if (this.locationID) {
      try {
        const { url, method, contentType } = locationApi.getLocationDetails(
          undefined,
          {
            locationID: this.locationID,
          }
        );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        updateToken(result);
        if (result?.status === 'ok') {
          stateData = update(stateData, {
            locationDetails: { $set: result.data || {} },
          });
          stateData = update(stateData, { loadingHeader: { $set: false } });
          this.fetchLocationLatest(stateData);
        } else {
          stateData = update(stateData, { loadingHeader: { $set: false } });
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        stateData = update(stateData, { loadingHeader: { $set: false } });
        this._isMounted && handleNotification('error', error?.data);
        updateToken(error?.data);
      }

      this.handleState({ ...stateData });
    }
  };

  fetchLocationLatest = async (stateData: Partial<StateType>) => {
    const { userData, updateToken } = this.props;
    if (stateData?.locationDetails?.sensorSpecs && this.locationID) {
      try {
        const { url, method, contentType } = locationApi.getLocationLatest(
          undefined,
          {
            locationID: this.locationID,
          }
        );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;
        updateToken(result);

        if (result?.status === 'ok') {
          const gaugeSensors = result.data?.latestData?.Dnum;
          if (gaugeSensors) {
            const tempGaugeData = manageGaugeData({
              phenomList: stateData.locationDetails.sensorSpecs,
              gaugeSensors,
              locationID: this.locationID,
            });

            if (tempGaugeData) {
              stateData = update(stateData, {
                gaugeList: { $set: tempGaugeData },
              });
            }
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error?.data);
        updateToken(error?.data);
      }
    }

    stateData = update(stateData, { loadingGauge: { $set: false } });

    this.handleState({ ...stateData }, () => {
      this.fetchLocationAverages(stateData);
    });
  };

  fetchLocationAverages = async (stateData?: Partial<StateType>) => {
    if (!stateData) {
      stateData = { ...this.state };
    }

    if (this.locationID && stateData?.locationDetails?.sensorSpecs) {
      const { userData, updateToken } = this.props;

      let paramsData: {
        lasthours?: string;
        from?: string;
        to?: string;
      } = {};

      if (stateData.inputFieldElements?.switcher) {
        paramsData = update(paramsData, {
          lasthours: {
            $set: stateData.inputFieldElements.lastHours || undefined,
          },
        });
      } else {
        paramsData = update(paramsData, {
          from: {
            $set: getZuluFormatUTC(
              stateData.inputFieldElements?.startDate,
              stateData.inputFieldElements?.startTime
            ),
          },
          to: {
            $set: getZuluFormatUTC(
              stateData.inputFieldElements?.endDate,
              stateData.inputFieldElements?.endTime
            ),
          },
        });
      }

      try {
        const { url, method, contentType, params } =
          locationApi.getLocationAverages(
            {
              ...paramsData,
            },
            { locationID: this.locationID }
          );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          params,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;

        updateToken(result);

        if (result?.status === 'ok') {
          if (result.data && stateData?.locationDetails?.sensorSpecs) {
            const tempData = manageChartData({
              phenomList: stateData.locationDetails.sensorSpecs,
              locationID: result.data.locationID,
              locationAveragesList: result.data.timeSeriesData,
            });

            if (tempData) {
              stateData = update(stateData, {
                chartList: { $set: tempData },
              });
            }
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error?.data);
        updateToken(error?.data);
      }
    }

    stateData = update(stateData, { loadingChart: { $set: false } });
    this.handleState({ ...stateData });
  };

  onChangeInputFieldElements = (name: string, value: any) => {
    const { inputFieldElements } = this.state;
    const tempFormElemets = update(inputFieldElements, {
      [name]: { $set: value },
    });

    if (name === 'autoUpdate') {
      if (value === true) {
        this.mapAutoUpdate = setInterval(() => {
          this.fetchLocationLatest({ ...this.state });
        }, 5 * 60000);
      } else {
        if (this.mapAutoUpdate) {
          clearInterval(this.mapAutoUpdate);

          this.mapAutoUpdate = undefined;
        }
      }
    }

    this.handleState({ inputFieldElements: tempFormElemets });
  };

  onRefreshButtonClick = () => {
    this.setState({ loadingChart: true, chartList: [] }, () => {
      this.fetchLocationAverages();
    });
  };

  render() {
    const {
      loadingHeader,
      locationDetails,
      loadingGauge,
      gaugeList,
      inputFieldElements,
      loadingChart,
      chartList,
    } = this.state;

    const { location, userData } = this.props;

    const floorPlanMapDetails = location?.state?.mapDetails;

    // console.log('locationDetails', locationDetails);

    return (
      <Fragment>
        <Row className="p-3">
          <Col xs={24}>
            {/* --- Header --- */}
            <Header
              loading={loadingHeader}
              locationDetails={locationDetails}
              userData={userData}
            />
            {/* --- Header --- end */}

            {/* --- MapGauge --- */}
            <MapGauge
              loading={loadingGauge}
              gaugeList={gaugeList}
              lat={locationDetails.lat || 0}
              lng={locationDetails.lng || 0}
              floorPlanMapDetails={floorPlanMapDetails}
            />
            {/* --- MapGauge --- end */}

            {/* --- Chart Input Fields --- */}
            <ChartInputFields
              formElements={inputFieldElements}
              onInputChange={this.onChangeInputFieldElements}
              onButtonClick={this.onRefreshButtonClick}
              loading={loadingChart}
              showAutoUpdate={true}
            />
            {/* --- Chart Input Fields --- end */}

            {/* --- Chart --- */}
            <Chart
              loading={loadingChart}
              chartList={chartList}
              switcher={inputFieldElements.switcher}
              lastHours={inputFieldElements.lastHours}
            />
            {/* --- Chart --- end */}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LocationDashboard);
