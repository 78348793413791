import { Card, Col, Row, Select } from 'antd';
import { Fragment } from 'react';
import { AiOutlineClear } from 'react-icons/ai';

import { FloorPlanListType } from '../../../@types/api-types';
import { airQualityFilterValues } from '../helpers';

type PropsType = {
  loading: boolean;
  selectedFloorPlan: string;
  selectedPhenoms: string[];
  selectedAirQualities: string[];
  onFloorPlanSelect: (value: string) => void;
  onPhenomSelect: (value: string[]) => void;
  onAirQualitySelect: (value: string[]) => void;
  floorPlanList: FloorPlanListType[];
  phenomCollection: string[];
  onPhenomClear: () => void;
  onAirQualityClear: () => void;
};

const FilterSection = ({
  loading,
  selectedFloorPlan,
  onFloorPlanSelect,
  floorPlanList,
  selectedAirQualities,
  selectedPhenoms,
  onAirQualitySelect,
  onPhenomSelect,
  phenomCollection,
  onAirQualityClear,
  onPhenomClear,
}: PropsType) => {
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Row>
              <Col xs={24} md={8} className="pr-md-1">
                <Select
                  disabled={loading}
                  size={'large'}
                  value={selectedFloorPlan}
                  onChange={onFloorPlanSelect}
                  style={{ width: '100%' }}>
                  {floorPlanList.map((el) => (
                    <Fragment key={el.uuid}>
                      <Select.Option
                        key={el.floorplanID}
                        value={el.floorplanID}>
                        {el.name}
                      </Select.Option>
                    </Fragment>
                  ))}
                </Select>
              </Col>

              <Col xs={24} md={8} className="pt-2 pt-md-0 pl-md-1 pr-md-1">
                <Select
                  allowClear
                  clearIcon={
                    <AiOutlineClear
                      size="1.2em"
                      color="#2880ca"
                      onClick={onPhenomClear}
                    />
                  }
                  mode="multiple"
                  disabled={loading}
                  size={'large'}
                  value={selectedPhenoms}
                  onChange={onPhenomSelect}
                  style={{ width: '100%' }}
                  placeholder="Select Phenom to filter">
                  {phenomCollection.map((el) => (
                    <Fragment key={el}>
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    </Fragment>
                  ))}
                </Select>
              </Col>

              <Col xs={24} md={8} className="pt-2 pt-md-0 pl-md-1">
                <Select
                  allowClear
                  clearIcon={
                    <AiOutlineClear
                      size="1.2em"
                      color="#2880ca"
                      onClick={onAirQualityClear}
                    />
                  }
                  mode="multiple"
                  disabled={loading}
                  size={'large'}
                  value={selectedAirQualities}
                  onChange={onAirQualitySelect}
                  style={{ width: '100%' }}
                  placeholder="Select Air Quality to filter">
                  {Object.entries(airQualityFilterValues).map(
                    ([key, value]) => (
                      <Fragment key={key}>
                        <Select.Option value={key}>{value}</Select.Option>
                      </Fragment>
                    )
                  )}
                </Select>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FilterSection;
