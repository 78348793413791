import { Carousel, CarouselProps, Col, Row } from 'antd';
import { TimeUnit } from 'chart.js';
import { Fragment, useEffect, useRef } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import update from 'immutability-helper';

import AntdCard from '../../../components-shared/AntdCard';
import { ChartListType } from '../../../@types';

type PropsType = {
  loading: boolean;
  chartList: ChartListType[];
  timeSpan: string;
  updateInterval: string;
  pauseInterval: string;
  transitionType: string;
};

function Chart({
  loading,
  chartList,
  timeSpan,
  pauseInterval,
  transitionType,
}: PropsType) {
  const _isMounted = useRef<boolean>(false);

  let chartType: TimeUnit = 'hour';
  if (Number(timeSpan) > 24) {
    chartType = 'day';
  }

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  let carouselProps: CarouselProps = {
    cssEase: `cubic-bezier(0.420, 0.000, 0.580, 1.000)`,
    autoplaySpeed: 3000,
    autoplay: true,
    dots: false,
    pauseOnHover: false,
  };

  if (Number(pauseInterval)) {
    carouselProps = update(carouselProps, {
      autoplaySpeed: { $set: Number(pauseInterval) * 1000 },
    });
  }

  if (transitionType === 'replace') {
    carouselProps = update(carouselProps, {
      cssEase: { $set: `all 500ms cubic-bezier(0.215, 0.610, 0.355, 1.000)` },
    });
  } else if (transitionType === 'fade') {
    carouselProps = update(carouselProps, {
      cssEase: { $set: `linear` },
      fade: { $set: true },
      style: { $set: { transition: 'opacity 2000s ease-in-out', opacity: 1 } },
    });
  }

  return (
    <Fragment>
      <Row className="pt-2 charts-root">
        <Col xs={24}>
          <AntdCard
            loading={loading}
            className="charts-card"
            bodyStyle={{ padding: 12, height: '60vh' }}
            elevate>
            {chartList && chartList.length > 0 ? (
              <Fragment>
                <Carousel {...carouselProps} className="carousel">
                  {chartList.map((el) => {
                    return (
                      <Fragment key={el.shortName}>
                        <LineChart
                          data={{ datasets: [el] }}
                          options={{
                            legend: { display: false },
                            title: {
                              display: true,
                              text: el.label,
                              fontSize: 30,
                              fontColor: '#808080',
                              padding: 20,
                            },
                            scales: {
                              xAxes: [
                                {
                                  type: 'time',
                                  time: {
                                    unit: chartType,
                                  },
                                  // distribution: 'series'
                                },
                              ],
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </Carousel>
              </Fragment>
            ) : (
              chartList.length === 0 &&
              !loading && (
                <Fragment>
                  <Row justify="center" align="middle" className="h-100">
                    <Col>
                      <span
                        style={{
                          fontSize: '16px',
                          fontStyle: 'italic',
                          color: 'rgb(54, 114, 248)',
                        }}>
                        {`No data within the last ${timeSpan || 12}  hours`}
                      </span>
                    </Col>
                  </Row>
                </Fragment>
              )
            )}
          </AntdCard>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Chart;
