import { Route, Redirect } from 'react-router-dom';
import AppLayout from '../components-shared/AppLayout';
import LoadingPage from '../components/LoadingPage';
import { UserDataType } from '../@types/api-types';
import { getFromLocal } from '../utils/manage-storage';
import { authRoutes } from './routes-list';
import { useSelector } from 'react-redux';
import { getUserData, getAuthError } from '../redux/selectors';
import ErrorPage from '../components-shared/ErrorPage';
import { Fragment } from 'react';
import { hasError } from '.';

type PropsType = {
  component: any;
  path: string;
  userData: Partial<UserDataType>;
};

function PrivateRoute({ component: Component, path, ...rest }: PropsType) {
  const userData = useSelector(getUserData);
  const userToken = getFromLocal('token');
  const error = useSelector(getAuthError);

  if (!userToken) {
    return (
      <Redirect
        to={{
          pathname: authRoutes.login(),
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      exact
      path={path}
      render={(props) =>
        userData.token ? (
          <AppLayout>
            <Component {...props} {...rest} />
          </AppLayout>
        ) : (
          <Fragment>
            {hasError(error) ? <ErrorPage /> : <LoadingPage />}
          </Fragment>
        )
      }
    />
  );
}

export default PrivateRoute;
