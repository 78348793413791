import { ApiResponseDataType } from '../../@types';
import {
  AUTH_SUCCESS,
  LOGOUT_REQUESTED,
  TOKEN_REQUESTED,
  UPDATE_TOKEN_REQUESTED,
} from '../actionTypes/auth.types';

export function authLogout() {
  return {
    type: LOGOUT_REQUESTED,
  };
}

export function getUserByToken() {
  return {
    type: TOKEN_REQUESTED,
  };
}

export function updateToken(result?: Partial<ApiResponseDataType>) {
  return {
    type: UPDATE_TOKEN_REQUESTED,
    payload: {
      result,
    },
  };
}

export function authSuccess(data = {}) {
  return {
    type: AUTH_SUCCESS,
    payload: {
      ...data,
    },
  };
}
