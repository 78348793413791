import { FormInputType } from '../../../../@types';

export const initFormElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      name: 'userName',
      type: 'text',
      placeholder: 'Enter username',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Username is required',
    label: 'Username',
  },
};

export type StateType = {
  formElements: FormElementsType;
  loading: boolean;
};

export type FormElementsType = {
  userName: FormInputType;
};
