import { all, put, select, takeEvery, call } from '../../utils/saga-libs';
import {
  ERRORED,
  LOGOUT,
  LOGOUT_REQUESTED,
  TOKEN_REQUESTED,
  TOKEN_SUCCESS,
  UPDATE_TOKEN_REQUESTED,
  UPDATE_TOKEN,
} from '../actionTypes/auth.types';
import { apiCall } from '../../api-services/api';
import { authApi } from '../../api-services/api-list';
import {
  getFromLocal,
  removeFromLocal,
  removeFromSession,
  setInLocal,
} from '../../utils/manage-storage';
import { ApiResponseDataType, ReduxStoreType } from '../../@types';
import { AxiosResponse } from 'axios';

export default function* watcherSaga() {
  yield all([
    takeEvery(LOGOUT_REQUESTED, authLogoutSaga),
    takeEvery(TOKEN_REQUESTED, getUserByTokenSaga),
    takeEvery(UPDATE_TOKEN_REQUESTED, updateTokenSaga),
  ]);
}

function* authLogoutSaga() {
  const local = localStorage && Object.keys(localStorage);
  const session = sessionStorage && Object.keys(sessionStorage);
  if (local && local.length > 0) {
    local.forEach((item) => {
      removeFromLocal(item);
    });
  }
  if (session && session.length > 0) {
    session.forEach((item) => {
      removeFromSession(item);
    });
  }

  yield put({ type: LOGOUT });
}

function* getUserByTokenSaga() {
  const userToken = getFromLocal('token');
  if (userToken) {
    try {
      const tokenLogin = authApi.getTokenLogin();
      const response: AxiosResponse<any> = yield call(apiCall, {
        storeToken: userToken,
        url: tokenLogin.url,
        method: tokenLogin.method,
        contentType: tokenLogin.contentType,
      });
      const result = response.data;
      if (result?.status === 'ok') {
        yield put({ type: TOKEN_SUCCESS, payload: { userData: result.data } });

        if (
          result?.data?.token &&
          userToken.toString() !== result.data.token.toString()
        ) {
          setInLocal('token', result.data.token);
        }
      }
    } catch (error) {
      yield put({ type: ERRORED, payload: { errorData: error.data } });
    }
  } else {
    yield put({ type: LOGOUT_REQUESTED });
  }
}

export function* updateTokenSaga(result: Partial<ApiResponseDataType>) {
  const token = getFromLocal('token');
  if (result && result?.status === 'invalidTokenError') {
    const error: ApiResponseDataType = yield select(
      (state: ReduxStoreType) => state.auth.error
    );
    if (!error.status) {
      yield put({ type: ERRORED, payload: { errorData: result } });
    }
  } else if (
    result &&
    token &&
    result?.status !== 'error' &&
    result?.token &&
    result?.token.toString() !== token.toString()
  ) {
    setInLocal('token', result.token);
    yield put({ type: UPDATE_TOKEN, payload: result.token });
  }
}
