import Modal from 'antd/lib/modal/Modal';
import { Fragment } from 'react';
import LeafletMap from '../../../components-shared/LeafletMap';

type PropsType = {
  showModal: boolean;
  mapZoom: number;
  lat: number;
  lng: number;
  onMapModalClose: () => void;
  onMapModalOkay: () => void;
  onMapMarkerMove: (lat: number, lng: number) => void;
};

const MapModal = ({
  showModal,
  lat,
  lng,
  onMapModalClose,
  onMapModalOkay,
  onMapMarkerMove,
}: PropsType) => {
  return (
    <Fragment>
      <Modal
        visible={showModal}
        onCancel={onMapModalClose}
        closable={false}
        width={800}
        onOk={onMapModalOkay}>
        <LeafletMap
          lat={lat}
          lng={lng}
          mapContainerProps={{ style: { height: '400px' } }}
          markerProps={{
            draggable: true,
            eventHandlers: {
              dragend: (event) =>
                onMapMarkerMove(
                  event?.target?.getLatLng?.()?.lat,
                  event?.target?.getLatLng?.()?.lng
                ),
            },
          }}
        />
      </Modal>
    </Fragment>
  );
};

export default MapModal;
