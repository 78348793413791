import {
  Fragment,
  useReducer,
  useEffect,
  Dispatch,
  CSSProperties,
} from 'react';
import { Col, Row } from 'antd';
import update from 'immutability-helper';

import useHttp from '../hooks/use-http';

import cssStyles from '../components/LocationKiosk/styles/locationKiosk.module.scss';
import { RouteComponentProps } from 'react-router';
import {
  AqiDataType,
  LocationListType,
  SensorListType,
  UserDataType,
} from '../@types/api-types';
import {
  StateType,
  reducer,
  initState,
  actionTypes,
} from '../components/LocationKiosk/helpers';
import {
  ApiResponseDataType,
  ChartListType,
  FloorPlanMapDetailsType,
  GaugeListType,
  ReducerHookActionType,
} from '../@types';
import useMounted from '../hooks/use-isMounted';
import { locationApi } from '../api-services/api-list';
import { AxiosResponse } from 'axios';
import HeaderSection from '../components/LocationKiosk/HeaderSection';
import BoxSection from '../components/LocationKiosk/BoxSection';
import { handleNotification } from '../utils/notification-handler';
import { apiCall } from '../api-services/api';
import AntdCoverSpinner from '../components-shared/AntdCoverSpinner';
import { manageChartData, manageGaugeData } from '../utils';
import GaugeSection from '../components/LocationKiosk/GaugeSection';
import MapSection from '../components/LocationKiosk/MapSection';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import ChartSection from '../components/LocationKiosk/ChartSection';
import { useDispatch } from 'react-redux';
import { updateToken } from '../redux/actions/auth.actions';

type PropsType = RouteComponentProps<
  { locationID: string },
  any,
  {
    mapDetails: FloorPlanMapDetailsType;
  }
> & {
  userData: Partial<UserDataType>;
};

const LocationKiosk = ({ location, match, userData }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);
  const { isLoading, sendRequest } = useHttp();
  const { isMounted } = useMounted();
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const locationID = match?.params?.locationID;
  const mapDetails = location?.state?.mapDetails;
  const { token: storeToken } = userData;
  const {
    loading,
    locationDetails,
    overallAqiIndex,
    gaugeDateTime,
    gaugeList,
    chartList,
  } = state;

  useEffect(() => {
    const fethGaugeChart = async ({
      details,
    }: {
      details: LocationListType;
    }) => {
      if (locationID && storeToken) {
        let stateData: Partial<StateType> = {};

        const { aqiData, gaugeData, gaugeDT, chartData } = await fetchData({
          locationID,
          isMounted,
          storeToken,
          sensorSpecs: details.sensorSpecs,
          dispatch,
        });

        if (aqiData.length > 0) {
          const matched = aqiData.find((el) => el.phenom === 'overall');
          if (matched) {
            stateData = update(stateData, {
              overallAqiIndex: { $set: matched.index },
            });
          }
        }

        if (gaugeData.length > 0) {
          stateData = update(stateData, {
            gaugeList: { $set: gaugeData },
          });
        }

        if (gaugeDT) {
          stateData = update(stateData, {
            gaugeDateTime: { $set: gaugeDT },
          });
        }

        if (chartData.length > 0) {
          stateData = update(stateData, {
            chartList: { $set: chartData },
          });
        }

        dispatchToState({
          type: actionTypes.setState,
          payload: { ...stateData, locationDetails: details, loading: false },
        });
      }
    };

    if (locationID && storeToken) {
      const { url, method, contentType } = locationApi.getLocationDetails(
        undefined,
        { locationID }
      );

      const handleResponse = (response: AxiosResponse<ApiResponseDataType>) => {
        const result = response.data;

        const data: LocationListType = result.data;

        if (data) {
          fethGaugeChart({ details: data });
        }
      };

      sendRequest({ url, method, contentType, storeToken }, handleResponse);
    }
  }, [dispatch, isMounted, locationID, sendRequest, storeToken]);

  const styles: CSSProperties = {};
  if (screens.xl) {
    styles.overflow = 'hidden';
  }

  return (
    <Fragment>
      <AntdCoverSpinner active={isLoading || loading}>
        <Row style={{ ...styles }} className={cssStyles.wrapper}>
          <Col xs={24} className="content pb-4 pb-xl-0">
            <HeaderSection locationName={locationDetails.name} />

            <Row>
              <Col xs={24} xl={12}>
                <BoxSection overallAqiIndex={overallAqiIndex} />
                <GaugeSection
                  gauges={gaugeList}
                  gaugeDateTime={gaugeDateTime}
                  loading={isLoading || loading}
                />
                {locationDetails.lat !== undefined && (
                  <MapSection
                    mapDetails={mapDetails}
                    lat={locationDetails.lat || 0}
                    lng={locationDetails.lng || 0}
                  />
                )}
              </Col>

              {/* --- Chart --- */}
              <Col xs={24} xl={12}>
                <ChartSection
                  charts={chartList}
                  loading={isLoading || loading}
                />
              </Col>
              {/* --- Chart --- end */}
            </Row>
          </Col>
        </Row>
      </AntdCoverSpinner>
    </Fragment>
  );
};

export default LocationKiosk;

const fetchData = async ({
  locationID,
  isMounted,
  storeToken,
  sensorSpecs,
  dispatch,
}: {
  locationID: string;
  isMounted: boolean;
  storeToken: string;
  sensorSpecs: SensorListType[];
  dispatch: Dispatch<any>;
}) => {
  let aqiData: AqiDataType[] = [];
  let gaugeData: GaugeListType[] = [];
  let gaugeDT = '';
  let chartData: ChartListType[] = [];
  try {
    const getDaqi = locationApi.getDaqi(undefined, { locationID });
    const getLocationLatest = locationApi.getLocationLatest(undefined, {
      locationID,
    });
    const getLocationAverages = locationApi.getLocationAverages(
      { lasthours: '24' },
      {
        locationID,
      }
    );
    const responses = await Promise.all([
      apiCall({
        storeToken,
        url: getDaqi.url,
        method: getDaqi.method,
        contentType: getDaqi.contentType,
      }),
      apiCall({
        storeToken,
        url: getLocationLatest.url,
        method: getLocationLatest.method,
        contentType: getLocationLatest.contentType,
      }),
      apiCall({
        storeToken,
        url: getLocationAverages.url,
        method: getLocationAverages.method,
        params: getLocationAverages.params,
        contentType: getLocationAverages.contentType,
      }),
    ]);
    if (responses.length > 0) {
      if (responses?.[0]?.data?.data) {
        aqiData = responses[0].data.data;
      }

      if (responses?.[1]?.data?.data) {
        const data = responses[1].data.data;

        const gaugeSensors = data?.latestData?.Dnum;
        if (data?.latestData?.TS) {
          gaugeDT = data.latestData.TS;
        }
        if (sensorSpecs.length > 0 && gaugeSensors) {
          let tempGaugeData = manageGaugeData({
            phenomList: sensorSpecs,
            locationID,
            gaugeSensors,
          });
          if (tempGaugeData) {
            tempGaugeData = tempGaugeData.filter((el) => {
              if (
                el.shortName.toUpperCase() === 'TEMP'.toUpperCase() ||
                el.shortName.toUpperCase() === 'NO2'.toUpperCase() ||
                el.shortName.toUpperCase() === 'O3'.toUpperCase() ||
                el.shortName.toUpperCase() === 'PM10'.toUpperCase() ||
                el.shortName.toUpperCase() === 'PM2.5'.toUpperCase()
              ) {
                return el;
              }
              return null;
            });

            gaugeData = [...tempGaugeData];
          }
        }
      }

      if (responses?.[2]?.data?.data) {
        const data = responses[2].data.data;

        let tempChartData = manageChartData({
          phenomList: sensorSpecs,
          locationID,
          locationAveragesList: data.timeSeriesData,
        });

        if (tempChartData) {
          tempChartData = tempChartData.filter((el) => {
            if (
              el.shortName.toUpperCase() === 'NO2'.toUpperCase() ||
              el.shortName.toUpperCase() === 'O3'.toUpperCase() ||
              el.shortName.toUpperCase() === 'PM10'.toUpperCase() ||
              el.shortName.toUpperCase() === 'PM2.5'.toUpperCase()
            ) {
              return el;
            }
            return null;
          });

          chartData = [...tempChartData];
        }
      }
    }
  } catch (error) {
    isMounted && handleNotification('error', error?.data);
    dispatch(updateToken(error?.data));
  }

  return { aqiData, gaugeData, gaugeDT, chartData };
};
