import { Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'antd';
import Leaflet from 'leaflet';

import { FloorPlanMapDetailsType } from '../../../@types';
import { getMapPoint } from '../../../utils/leaflet-helpers';
import cssStyles from '../styles/floorPlans.module.scss';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FloorPlanLocationsWithPosition } from '../helpers';
import { useHistory } from 'react-router';
import { locationRoutes } from '../../../routes/routes-list';
import GaugeSection from '../GaugeSection';
import MapSection from '../MapSection';

type PropsType = {
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  mapDetails: FloorPlanMapDetailsType;
  onLocationSelect: (value: string) => void;
  selectedLocation: string;
  loading: boolean;
  selectedPhenoms: string[];
};

const LocationListSection = ({
  floorPlanLocationList,
  mapDetails,
  onLocationSelect,
  selectedLocation,
  loading,
  selectedPhenoms,
}: PropsType) => {
  const [floorPlanLocations, setFloorPlanLocations] = useState<
    FloorPlanLocationsWithPosition[]
  >([]);
  const [mapInstance, setMapInstance] = useState<Leaflet.Map | undefined>();
  const [unprojectZoom, setUnprojectZoom] = useState<number | undefined>();
  const screens = useBreakpoint();
  const history = useHistory();

  useEffect(() => {
    if (
      floorPlanLocationList.length !== floorPlanLocations.length &&
      mapInstance &&
      unprojectZoom
    ) {
      let tempData = floorPlanLocationList.map((el) => {
        let tempObj = { ...el };
        const point = getMapPoint({ x: el.x, y: el.y });
        const customLatLng = mapInstance.unproject(point, unprojectZoom);

        tempObj = {
          ...tempObj,
          position: {
            ...tempObj.position,
            lat: customLatLng?.lat || 0,
            lng: customLatLng?.lng || 0,
          },
        };

        tempObj.outerPolygonLatLng = el.region?.outerPolygon?.map((el2) => {
          const point = getMapPoint({ x: el2.x, y: el2.y });
          const customLatLng = mapInstance.unproject(point, unprojectZoom);
          return { lat: customLatLng.lat, lng: customLatLng.lng };
        });

        tempObj.innerPolygonsLatLng = el.region?.innerPolygons?.map((el3) => {
          return el3.map((el4) => {
            const point = getMapPoint({ x: el4.x, y: el4.y });
            const customLatLng = mapInstance.unproject(point, unprojectZoom);
            return { lat: customLatLng.lat, lng: customLatLng.lng };
          });
        });

        return { ...tempObj };
      });

      setFloorPlanLocations(tempData);
    }
  }, [
    floorPlanLocationList,
    floorPlanLocations.length,
    mapInstance,
    unprojectZoom,
  ]);

  const handleNavigateKioskView = () => {
    if (selectedLocation) {
      const selected = floorPlanLocations.find(
        (el) => el.locationID === selectedLocation
      );
      if (selected) {
        history.push({
          pathname: locationRoutes.kiosk({
            locationID: selected.locationID,
          }),
          state: {
            mapDetails: {
              ...mapDetails,
              currentCoordinates: {
                x: selected.x,
                y: selected.y,
              },
            },
          },
        });
      }
    }
  };

  const handleNavigateCarouselView = () => {
    if (selectedLocation) {
      const selected = floorPlanLocations.find(
        (el) => el.locationID === selectedLocation
      );
      if (selected) {
        history.push({
          pathname: locationRoutes.carousel({
            locationID: selected.locationID,
          }),
          // state: {
          //   redirectLink: locationRoutes.floorPlan(),
          // },
        });
      }
    }
  };

  const handleNavigateHomeView = () => {
    if (selectedLocation) {
      const selected = floorPlanLocations.find(
        (el) => el.locationID === selectedLocation
      );
      if (selected) {
        history.push({
          pathname: locationRoutes.dashboard({
            locationID: selected.locationID,
          }),
          state: {
            mapDetails: {
              ...mapDetails,
              currentCoordinates: {
                x: selected.x,
                y: selected.y,
              },
            },
          },
        });
      }
    }
  };

  let isButtonDisabled = true;
  if (selectedLocation) {
    isButtonDisabled = false;
  }

  return (
    <Fragment>
      <Row className="py-3">
        <Col xs={24} md={8} style={{ height: '400px' }}>
          <Card
            loading={loading}
            className="h-100"
            bodyStyle={{ height: '100%', padding: 0 }}>
            <Row
              justify="center"
              align="middle"
              // style={{
              //   height: screens.xs ? '50%' : undefined,
              // }}
              className="p-3">
              <Col
                style={{
                  // transform: screens.xs ? `rotate(90deg)` : undefined,
                  fontWeight: 'bold',
                }}>
                Dashboard
              </Col>
            </Row>

            <Fragment>
              <Row
                justify="center"
                align="middle"
                className={cssStyles.boxRoot}>
                <LocationBox
                  floorPlanLocations={floorPlanLocations}
                  onLocationSelect={onLocationSelect}
                  selectedLocation={selectedLocation}
                />
              </Row>
              <Row
                justify="center"
                align="middle"
                className={`px-3 mt-2 ${cssStyles.navigation}`}>
                <Col xs={24} sm={16} xl={8}>
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleNavigateKioskView}
                    block>
                    Kiosk View
                  </Button>
                </Col>
                <Col
                  xs={24}
                  sm={16}
                  xl={8}
                  className="py-2 py-xl-0 px-xl-2 mx-2 mx-xl-0">
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleNavigateCarouselView}
                    block>
                    Carousel View
                  </Button>
                </Col>
                <Col xs={24} sm={16} xl={8}>
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleNavigateHomeView}
                    block>
                    Device Homepage
                  </Button>
                </Col>
              </Row>
            </Fragment>
          </Card>
          {screens.md && (
            <GaugeSection
              floorPlanLocationList={floorPlanLocationList}
              selectedLocation={selectedLocation}
              loading={loading}
              selectedPhenoms={selectedPhenoms}
            />
          )}
        </Col>

        <MapSection
          loading={loading}
          floorPlanLocationList={floorPlanLocations}
          mapDetails={mapDetails}
          onLocationSelect={onLocationSelect}
          selectedLocation={selectedLocation}
          selectedPhenoms={selectedPhenoms}
          mapInstance={mapInstance}
          setMapInstance={setMapInstance}
          unprojectZoom={unprojectZoom}
          setUnprojectZoom={setUnprojectZoom}
        />
      </Row>
      {screens.sm && !screens.md && (
        <GaugeSection
          floorPlanLocationList={floorPlanLocationList}
          selectedLocation={selectedLocation}
          loading={loading}
          selectedPhenoms={selectedPhenoms}
        />
      )}
    </Fragment>
  );
};

export default LocationListSection;

const LocationBox = ({
  floorPlanLocations,
  onLocationSelect,
  selectedLocation,
}: {
  floorPlanLocations: FloorPlanLocationsWithPosition[];
  onLocationSelect: (value: string) => void;
  selectedLocation: string;
}) => {
  const screens = useBreakpoint();

  let width = 90;
  if (screens.xl) {
    width = 40;
  }

  return (
    <Fragment>
      {floorPlanLocations.length > 0 ? (
        floorPlanLocations.map((el) => {
          return (
            <Fragment key={el.uuid}>
              <Col
                data-locationid={el.locationID}
                xs={24}
                xl={12}
                style={{
                  maxWidth: `${width}%`,
                  flex: `0 0 ${width}%`,
                }}
                className={`p-2 m-2 ${cssStyles.box} 
                ${
                  el.locationID === selectedLocation
                    ? cssStyles.boxSelected
                    : ''
                }`}
                onClick={() => onLocationSelect(el.locationID)}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: `100%`,
                    height: '1.2rem',
                    borderRadius: '0.85rem',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor: el.colorCode,
                  }}></div>
                <div className="location-id">{el.locationName}</div>
                <div className="location-name">{el.locationDescription}</div>
              </Col>
            </Fragment>
          );
        })
      ) : (
        <Col>
          <span
            style={{
              fontSize: '16px',
              fontStyle: 'italic',
              color: 'rgb(54, 114, 248)',
            }}>
            No floorplans to display
          </span>
        </Col>
      )}
    </Fragment>
  );
};
