import { Fragment, useState } from 'react';
import { MapContainer, TileLayer, CircleMarker } from 'react-leaflet';
import Leaflet from 'leaflet';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { ReduxStoreType } from '../../../@types';

import 'leaflet/dist/leaflet.css';

import cssStyles from '../styles/locationList.module.scss';
import { LocationListType } from '../../../@types/api-types';
import MapPopup from './MapPopup';

const currentTime = moment.utc();

type PropsType = {
  dataList: LocationListType[];
};

function LocationMap({ dataList }: PropsType) {
  if (dataList && dataList.length === 0) {
    return (
      <Fragment>
        <InitialMap />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MapWithData dataList={dataList} />
    </Fragment>
  );
}

export default LocationMap;

function InitialMap() {
  const userData = useSelector((state: ReduxStoreType) => state.auth.userData);

  return (
    <MapContainer
      className={cssStyles.map}
      zoom={12}
      center={[userData.lat || 51.507365, userData.lng || -0.12766]}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
}

function MapWithData({ dataList }: { dataList: LocationListType[] }) {
  const userData = useSelector((state: ReduxStoreType) => state.auth.userData);
  const [center, setCenter] = useState({
    lat: userData.lat || 51.507365,
    lng: userData.lng || -0.12766,
  });
  const [bounds, setBounds] = useState<Leaflet.LatLngBounds | undefined>(
    undefined
  );

  function handleMap(map: Leaflet.Map) {
    const arrayOfLatLngs: [number, number][] = [];
    if (dataList.length > 0) {
      dataList.forEach((item) => {
        if ((item.lat || item.lat === 0) && (item.lng || item.lng === 0)) {
          arrayOfLatLngs.push([item.lat, item.lng]);
        }
      });
      const mapBounds = new Leaflet.LatLngBounds(arrayOfLatLngs);
      setBounds(mapBounds);
    }
    setCenter({ lat: map.getCenter().lat, lng: map.getCenter().lng });
  }

  return (
    <MapContainer
      whenCreated={handleMap}
      className={cssStyles.map}
      zoom={10}
      maxZoom={18}
      center={center}
      bounds={bounds}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {dataList.length > 0 &&
        dataList.map((item) => {
          const markerProps = {
            color: '#808080',
          };
          if (item?.icon) {
            markerProps.color = item.icon;
          }

          if (item.lastContact) {
            const lastContact = moment.utc(item?.lastContact);

            const timeDifference = lastContact.diff(currentTime, 'hours', true);

            if (timeDifference > 2 && item?.icon === 'airsensamapicon.png') {
              markerProps.color = '#808080';
            }
          }

          return (
            <Fragment key={item.uuid}>
              <CircleMarker
                {...markerProps}
                center={[item.lat || 0, item.lng || 0]}
                fillOpacity={0.8}
                stroke={false}>
                <MapPopup locationId={item.locationID} />
              </CircleMarker>
            </Fragment>
          );
        })}
    </MapContainer>
  );
}
