import { Map } from 'leaflet';
import { useEffect, useState } from 'react';
import { FloorPlanMapDetailsType } from '../@types';
import { getMapPoint } from '../utils/leaflet-helpers';

const useFloorPlanMapCenter = (mapDetails: FloorPlanMapDetailsType) => {
  const [mapInstance, setMapInstance] = useState<Map | undefined>();
  const [unprojectZoom, setUnprojectZoom] = useState<number | undefined>();
  const [position, setPosition] = useState<
    { lat: number; lng: number } | undefined
  >();

  useEffect(() => {
    if (mapInstance && mapDetails?.currentCoordinates?.x && unprojectZoom) {
      const point = getMapPoint({
        x: mapDetails.currentCoordinates?.x,
        y: mapDetails.currentCoordinates?.y,
      });

      const position = mapInstance.unproject(point, unprojectZoom);
      setPosition({ lat: position.lat, lng: position.lng });
    }
  }, [mapDetails?.currentCoordinates, mapInstance, unprojectZoom]);

  return { position, setMapInstance, setUnprojectZoom };
};

export default useFloorPlanMapCenter;
