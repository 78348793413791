import { Fragment, Component, FormEvent } from 'react';
import { Button, Col, message, Row } from 'antd';
import update from 'immutability-helper';
import axios from 'axios';

import logo from '../assets/images/logo.png';
import cssStyles from '../components/Login/styles/login.module.scss';
import {
  StateType,
  initFormElements,
  FormElementsType,
} from '../components/Login/helpers';
import { checkValidation } from '../utils/validation';
import { getSingleInputElement } from '../utils/get-input-element';
import { authApi } from '../api-services/api-list';
import { apiCall } from '../api-services/api';
import { handleNotification } from '../utils/notification-handler';
import { authSuccess } from '../redux/actions/auth.actions';
import { connect, ConnectedProps } from 'react-redux';
import { setInLocal } from '../utils/manage-storage';
import ChangePasswordModal from '../components-shared/ChangePasswordModal';
import { Redirect, RouteComponentProps } from 'react-router';
import { locationRoutes } from '../routes/routes-list';
import ResetPasswordModal from '../components/Login/ResetPasswordModal';
import { ReduxStoreType } from '../@types';

type PropsType = RouteComponentProps & PropsFromRedux;

class Login extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: { ...initFormElements },
      loading: false,
      isButtonDisabled: false,
      showChangePassModal: false,
      showResetPassModal: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };
    let key: keyof typeof tempFormElements;

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      let isValid = true;
      for (key in tempFormElements) {
        if (!tempFormElements[key].valid) {
          isValid = false;
        }
      }

      this.handleState({
        formElements: tempFormElements,
        isButtonDisabled: !isValid,
      });
    }
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault?.();

    const { formElements } = this.state;
    const { authSuccess, history } = this.props;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;
    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        userName: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation,
            ),
          },
        },
      });
    }

    this.handleState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (!tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this.handleState({ loading: true });

    const data = {
      userName: tempFormElements.userName.value,
      password: tempFormElements.password.value,
    };

    let stateData: Partial<StateType> = {};

    try {
      const { url, method, contentType, params } = authApi.getLogin({
        username: data.userName,
        password: data.password,
      });

      const response = await apiCall({
        url,
        method,
        contentType,
        params,
        cancelToken: this.axiosCancelSource.token,
      });

      const result = response?.data;

      stateData.loading = false;

      if (result) {
        if (result?.status === 'ok') {
          setInLocal('token', result.data.token);
          stateData.formElements = { ...initFormElements };
          authSuccess({ userData: result.data });

          history.push(locationRoutes.list());
        } else {
          stateData.loading = false;
          this._isMounted && handleNotification('error', result);
        }
      } else {
        stateData.loading = false;
      }
    } catch (error) {
      stateData.loading = false;
      let result = error?.data;
      if (result?.status === 'changePassword') {
        stateData.showChangePassModal = true;
      } else {
        this._isMounted && handleNotification('error', result);
      }
    }

    this.handleState({ ...stateData });
  };

  handleChangePassModal = (params?: boolean) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            showChangePassModal: !prevState.showChangePassModal,
          };
        },
        () => {
          if (params) {
            const { history } = this.props;
            history.push(locationRoutes.list());
          }
        },
      );
  };

  handleResetPasswordModal = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          showResetPassModal: !prevState.showResetPassModal,
        };
      });
  };

  render() {
    const {
      formElements,
      isButtonDisabled,
      showChangePassModal,
      loading,
      showResetPassModal,
    } = this.state;

    const { userData } = this.props;

    const single = getSingleInputElement({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 3],
    });

    if (userData.token) {
      if (userData?.type === 'INTERNAL') {
        return <Redirect to={locationRoutes.floorPlan()} />;
      } else {
        return <Redirect to={locationRoutes.list()} />;
      }
    }

    return (
      <Fragment>
        <Row align="middle" className={`${cssStyles.wrapper}`}>
          <Col lg={10} xs={24} className="formSection">
            <Row align="middle" justify="center" className="h-100 px-3">
              <Col>
                <Row>
                  <Col>
                    <img src={logo} alt="AirSensa logo" className="logo" />
                  </Col>
                </Row>
                <form noValidate onSubmit={this.onFormSubmit}>
                  {single}

                  <Row className="pb-3">
                    <Col
                      style={{ cursor: 'pointer', color: '#1890ff' }}
                      onClick={this.handleResetPasswordModal}>
                      Reset Password?
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={isButtonDisabled}
                        loading={loading}>
                        SIGN IN
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>

        {showChangePassModal && (
          <ChangePasswordModal
            showModal={showChangePassModal}
            handleModal={this.handleChangePassModal}
            userName={formElements.userName.value}
            userPassword={formElements.password.value}
          />
        )}

        {showResetPassModal && (
          <ResetPasswordModal
            showModal={showResetPassModal}
            handleModal={this.handleResetPasswordModal}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  authSuccess: authSuccess,
};

function mapStateToProps(state: ReduxStoreType) {
  return {
    userData: state.auth.userData,
  };
}

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
