import { FC, Fragment, memo, ReactNode } from 'react';
import { Spin, SpinProps } from 'antd';

type PropsType = SpinProps & {
  active?: boolean;
  children?: ReactNode;
};

const AntdCoverSpinner: FC<PropsType> = ({ active, children, size }) => {
  return (
    <Fragment>
      {active ? <Spin size={size || 'large'}>{children}</Spin> : children}
    </Fragment>
  );
};

export default memo(AntdCoverSpinner);
