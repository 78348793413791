import update from 'immutability-helper';
import {
  ReducerHookActionType,
  FloorPlanMapDetailsType,
} from '../../../@types';

import {
  FloorPlanListType,
  FloorPlanLocationListType,
  LatLngCoordinatesType,
} from '../../../@types/api-types';
import { sharedReducerHookActionTypes } from '../../../utils/constants';

export const initState: StateType = {
  loading: true,
  floorPlanList: [],
  floorPlanLocationList: [],
  initFloorPlanLocationsList: [],
  mapDetails: {},
  phenomCollection: [],

  selectedPhenoms: [],
  selectedAirQualities: [],

  selectedLocation: '',
  isFloorplanChanged: false,
};

export type StateType = {
  loading: boolean;
  floorPlanList: FloorPlanListType[];
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  initFloorPlanLocationsList: FloorPlanLocationsWithPosition[];
  mapDetails: FloorPlanMapDetailsType;
  phenomCollection: string[];

  selectedPhenoms: string[];
  selectedAirQualities: string[];

  selectedLocation: string;
  isFloorplanChanged: boolean;
};

export const actionTypes = {
  ...sharedReducerHookActionTypes,
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case actionTypes.setState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};

export const airQualityFilterValues = {
  red: 'Red',
  amber: 'Amber',
  green: 'Green',
};

export type FloorPlanLocationsWithPosition = FloorPlanLocationListType & {
  colorCode?: string;
  colorName?: string;
  position?: LatLngCoordinatesType;
  outerPolygonLatLng?: LatLngCoordinatesType[];
  innerPolygonsLatLng?: LatLngCoordinatesType[][];
};
