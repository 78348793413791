import { useState, useCallback, useRef, useEffect } from 'react';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { handleNotification } from '../utils/notification-handler';
import { useDispatch } from 'react-redux';
import { authLogout } from '../redux/actions/auth.actions';
import { handleApiError } from '../utils';
import { ApiResponseDataType } from '../@types';
import { RequestConfigType } from '../@types/api-types';

const useHttp = (loading?: boolean) => {
  const [isLoading, setIsLoading] = useState(loading || false);
  const isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  const sendRequest = useCallback(
    async (
      requestConfig: RequestConfigType & {
        storeToken: string;
      },
      applyData?: (response: AxiosResponse<ApiResponseDataType>) => void
    ) => {
      isMounted.current && setIsLoading(true);
      try {
        const headers = {
          'X-API-KEY': requestConfig.storeToken,
          'Content-Type': requestConfig.contentType || 'application/json',
        };

        const response: AxiosResponse<ApiResponseDataType> = await axios({
          ...requestConfig,
          cancelToken: axiosCancelSource.current?.token,
          headers,
        });

        isMounted.current && applyData?.(response);
      } catch (error) {
        if (axios.isCancel(error)) {
          // isMounted.current &&
          //   handleNotification('error', { message: 'Axios Request Cancelled' });
        } else {
          const errorResponse = handleApiError(error);
          isMounted.current &&
            handleNotification('error', {
              message: errorResponse?.data?.message,
            });

          if (errorResponse?.data?.status === 'invalidTokenError') {
            dispatch(authLogout());
          }
        }
      }
      isMounted.current && setIsLoading(false);
    },
    [dispatch]
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
