import { FormInputType } from '../../../@types';

export const settingsDialogInitFormElements: SettingsDialogFormElementsType = {
  timeSpan: {
    elementType: 'input',
    elementConfig: {
      name: 'timeSpan',
      type: 'text',
      placeholder: 'Enter Time Span',
    },
    value: '12',
    validation: {
      required: true,
      isNumeric: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Time Span is required',
    label: 'Time Span (hours)',
  },
  updateInterval: {
    elementType: 'input',
    elementConfig: {
      name: 'updateInterval',
      type: 'text',
      placeholder: 'Enter update interval',
    },
    value: '5',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Update Interval is required',
    label: 'Update Interval (mins)',
  },
  pauseInterval: {
    elementType: 'input',
    elementConfig: {
      name: 'pauseInterval',
      type: 'text',
      placeholder: 'Enter pause interval',
    },
    value: '3',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Pause Interval is required',
    label: 'Pause Interval (seconds)',
  },
  transitionType: {
    elementType: 'select',
    elementConfig: {
      name: 'transitionType',
      placeholder: 'Enter transition type',
    },
    value: 'slide',
    optionValues: [
      {
        value: 'replace',
        text: 'Replace',
      },
      {
        value: 'slide',
        text: 'Slide',
      },
      {
        value: 'fade',
        text: 'Fade',
      },
    ],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Transition Type is required',
    label: 'Transition Type',
  },
};

export type SettingsDialogFormElementsType = {
  timeSpan: FormInputType;
  updateInterval: FormInputType;
  pauseInterval: FormInputType;
  transitionType: FormInputType;
};
