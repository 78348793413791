import { Col, Row } from 'antd';
import { Fragment, memo } from 'react';

type PropsType = {
  locationName?: string;
};

const HeaderSection = ({ locationName }: PropsType) => {
  return (
    <Fragment>
      <Row className="header" justify="center" align="middle">
        <Col xl={4} className="text-center">
          {locationName}
        </Col>
      </Row>
    </Fragment>
  );
};

export default memo(HeaderSection);
