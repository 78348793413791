import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Fragment } from 'react';
import AntdCard from '../AntdCard';
import { InputFieldsFormElementsType, lastHoursOptions } from './helpers';

const { Option } = Select;

type PropsType = {
  formElements: InputFieldsFormElementsType;
  onInputChange: (name: string, value: any) => void;
  onButtonClick: () => void;
  loading?: boolean;
  showAutoUpdate?: boolean;
  showSplitTime?: boolean;
  isDisabled?: boolean;
};

function ChartInputFields({
  formElements,
  onInputChange,
  onButtonClick,
  loading,
  showAutoUpdate = false,
  showSplitTime = false,
  isDisabled,
}: PropsType) {
  let isBtnDisabled = true;
  if (isDisabled) {
    isBtnDisabled = true;
  } else if (formElements.switcher) {
    isBtnDisabled = !formElements.lastHours;
  } else {
    isBtnDisabled = !formElements.startDate || !formElements.endDate;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <AntdCard style={{ minHeight: '300px' }} elevate>
            <Row>
              <Col>Control Type</Col>
            </Row>
            <Row className="py-2">
              <Col>
                <Switch
                  defaultChecked={formElements.switcher}
                  onChange={(value) => onInputChange('switcher', value)}
                />
                &nbsp;&nbsp;
                {formElements.switcher ? 'Time Span' : 'Date/Time'}
              </Col>
            </Row>

            {formElements.switcher ? (
              <Fragment>
                {/* --- Time Span --- */}
                <TimeSpan
                  formElements={formElements}
                  onInputChange={onInputChange}
                  showAutoUpdate={showAutoUpdate}
                />
                {/* --- Time Span --- end */}
              </Fragment>
            ) : (
              <Fragment>
                {/* --- Date Time --- */}
                <DateTime
                  formElements={formElements}
                  onInputChange={onInputChange}
                  showAutoUpdate={showAutoUpdate}
                />
                {/* --- Date Time --- end */}
              </Fragment>
            )}

            {showSplitTime && (
              <Row className="pt-2">
                <Col xs={24} lg={8}>
                  <Input
                    placeholder="Split Time"
                    style={{ width: '100%' }}
                    size="large"
                    value={formElements.splitTime}
                    name="splitTime"
                    onChange={(event) => {
                      if (!isNaN(Number(event.currentTarget.value))) {
                        return onInputChange(
                          event.currentTarget.name,
                          event.currentTarget.value
                        );
                      }
                      return;
                    }}
                  />
                </Col>
              </Row>
            )}

            <Row className="pt-3">
              <Col xs={8}>
                <Button
                  loading={loading}
                  htmlType="button"
                  type="primary"
                  block
                  disabled={isBtnDisabled}
                  onClick={onButtonClick}>
                  Refresh
                </Button>
              </Col>
            </Row>
          </AntdCard>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ChartInputFields;

function TimeSpan({
  formElements,
  onInputChange,
  showAutoUpdate,
}: {
  formElements: InputFieldsFormElementsType;
  onInputChange: (name: string, value: any) => void;
  showAutoUpdate: boolean;
}) {
  return (
    <Row className="py-2" align="middle">
      <Col xs={24} lg={8}>
        {/* <label htmlFor="unit">Last Hours</label> */}
        <Select
          style={{ width: '100%' }}
          size="large"
          value={formElements.lastHours}
          onChange={(value) => onInputChange('lastHours', value)}>
          {lastHoursOptions.map(({ text, value }) => (
            <Option value={value} key={value}>
              {text}
            </Option>
          ))}
        </Select>
      </Col>

      {showAutoUpdate && (
        <Col lg={4} className="pl-lg-2">
          <Checkbox
            name="autoUpdate"
            checked={formElements.autoUpdate}
            onChange={(event) => {
              onInputChange('autoUpdate', event.target.checked);
            }}>
            Auto Update
          </Checkbox>
        </Col>
      )}
    </Row>
  );
}

function DateTime({
  formElements,
  onInputChange,
  showAutoUpdate,
}: {
  formElements: InputFieldsFormElementsType;
  onInputChange: (name: string, value: any) => void;
  showAutoUpdate: boolean;
}) {
  return (
    <Row align="middle" className="py-2">
      <Col xs={24} lg={8} className="pt-2">
        <label htmlFor="startDate">Start Date</label>
        <DatePicker
          style={{ width: '100%' }}
          size="large"
          name="startDate"
          onChange={(date, dateString) =>
            onInputChange('startDate', dateString)
          }
        />
      </Col>

      <Col xs={24} lg={8} className="pt-2 pl-lg-2">
        <label htmlFor="startTime">Start Time</label>
        <TimePicker
          style={{ width: '100%' }}
          size="large"
          name="startTime"
          onChange={(time, timeString) =>
            onInputChange('startTime', timeString)
          }
        />
      </Col>

      <Col xs={24} lg={8} className="pt-2 pl-lg-2">
        <label htmlFor="endDate">End Date</label>
        <DatePicker
          style={{ width: '100%' }}
          size="large"
          name="endDate"
          onChange={(date, dateString) => onInputChange('endDate', dateString)}
        />
      </Col>

      <Col xs={24} lg={8} className="pt-2">
        <label htmlFor="endTime">End Time</label>
        <TimePicker
          style={{ width: '100%' }}
          size="large"
          name="endTime"
          onChange={(time, timeString) => onInputChange('endTime', timeString)}
        />
      </Col>

      {showAutoUpdate && (
        <Col lg={4} className="pt-3 pt-lg-4 pl-lg-2">
          <Checkbox
            name="autoUpdate"
            checked={formElements.autoUpdate}
            onChange={(event) => {
              onInputChange('autoUpdate', event.target.checked);
            }}>
            Auto Update
          </Checkbox>
        </Col>
      )}
    </Row>
  );
}
