import { Fragment, ReactNode } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  PopupProps,
  MarkerProps,
  MapContainerProps,
} from 'react-leaflet';
import Leaflet from 'leaflet';
import { getLeafletIcon } from '../../utils/leaflet-helpers';
import { Col, Row } from 'antd';

import 'leaflet/dist/leaflet.css';

type PropsType = {
  lat: number;
  lng: number;
  zoom?: number;
  mapMarkerRender?: () => ReactNode;
  mapPopup?: () => ReactNode;
  mapPopupContent?: (params: { lat: number; lng: number }) => ReactNode;
  markerIconColor?: 'amber' | 'red' | 'green' | 'grey' | 'blue';
  popupProps?: Partial<PopupProps>;
  markerProps?: Partial<MarkerProps>;
  mapContainerProps?: Partial<MapContainerProps>;
};

function LeafletMap({
  zoom,
  lat,
  lng,
  mapMarkerRender,
  markerIconColor,
  mapPopup,
  mapPopupContent,
  markerProps,
  popupProps,
  mapContainerProps,
}: PropsType) {
  function handleMap(map: Leaflet.Map) {}

  return (
    <Fragment>
      <MapContainer
        {...mapContainerProps}
        whenCreated={handleMap}
        zoom={zoom || 12}
        center={[lat, lng]}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {mapMarkerRender ? (
          mapMarkerRender()
        ) : (
          <Marker
            {...markerProps}
            position={[lat, lng]}
            icon={getLeafletIcon(markerIconColor || 'blue')}>
            {mapPopup ? (
              mapPopup()
            ) : (
              <Popup {...popupProps}>
                {mapPopupContent ? (
                  mapPopupContent({ lat, lng })
                ) : (
                  <Row>
                    <Col xs={24}>Latitude: {lat}</Col>
                    <Col xs={24}> Longitude: {lng} </Col>
                  </Row>
                )}
              </Popup>
            )}
          </Marker>
        )}
      </MapContainer>
    </Fragment>
  );
}

export default LeafletMap;
