import { SelectOptionsType } from '../../../@types';

export const inputFieldFormElements: InputFieldsFormElementsType = {
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  lastHours: '3',
  splitTime: '',
  autoUpdate: false,
  switcher: true,
};

export type InputFieldsFormElementsType = {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  lastHours: string;
  splitTime?: string;
  autoUpdate: boolean;
  switcher: boolean;
};

export const lastHoursOptions: SelectOptionsType[] = [
  // {
  //   value: '',
  //   text: 'Since Midnight',
  // },
  {
    value: '3',
    text: 'Last 3 Hours',
  },
  {
    value: '24',
    text: 'Last 24 Hours',
  },
  {
    value: '48',
    text: 'Last 48 Hours',
  },
  {
    value: '120',
    text: 'Last 5 Days',
  },
];
