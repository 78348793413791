import React from 'react';

import CompassDial from './back2.png';
// import CompassNeedle from './pointer.png';
import CompassNeedle from './pointer_new.png';
import { StyleSheet, css } from 'aphrodite';

function CompassWithImages({
  rotateValue,
  dialWidth,
  dialHeight,
  needleHeight,
}: {
  rotateValue: number;
  dialWidth?: number;
  dialHeight?: number;
  needleHeight?: number;
}) {
  const imageRotateKeyframes = {
    '0%': {
      transform: 'rotate(0deg) translate(0%, -30%)',
    },
    '100%': {
      transform: `rotate(${rotateValue}deg) translate(0%, -30%)`,
    },
  };

  const styles = StyleSheet.create({
    compassParent: {
      width: '80px',
      height: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    compassDial: {
      width: dialWidth ? dialWidth + 'px' : '70px',
      height: dialHeight ? dialHeight + 'px' : '70px',
      position: 'absolute',
      // transform: `translate(-50%, -50%)`,
      // top: '50%',
      // left: '50%'
    },
    compassNeedle: {
      width: '30px',
      // height: '50px',
      height: needleHeight ? needleHeight + 'px' : '40px',
      position: 'absolute',
      // transform: `rotate(${props.rotateValue}deg)`
      // transform: `rotate(${props.rotateValue}deg) translate(0%, -30%)` // ## without animation
      // transform: `translate(-50%, -50%)`,
      // top: '50%',
      // left: '50%',
      // ## for animation
      animationDuration: '2s',
      animationIterationCount: 1,
      transitionTimingFunction: 'linear',
      animationFillMode: 'forwards',
      animationName: imageRotateKeyframes,
    },
  });

  return (
    <React.Fragment>
      <div className={css(styles.compassParent)}>
        <img src={CompassDial} alt="dial" className={css(styles.compassDial)} />
        <img
          src={CompassNeedle}
          alt="needle"
          className={css(styles.compassNeedle)}
        />
      </div>
    </React.Fragment>
  );
}

export default CompassWithImages;
