import update from 'immutability-helper';

import {
  ChartListType,
  GaugeListType,
  ReducerHookActionType,
} from '../../../@types';
import { sharedReducerHookActionTypes } from '../../../utils/constants';

export interface CustomPagesStateType {
  selectedPage: string;
  displayLists: { id: string; name: string }[];
  showModal: boolean;
  createPageInput: string;
}

export const customPagesInitState: CustomPagesStateType = {
  selectedPage: '',
  displayLists: [],
  showModal: false,
  createPageInput: '',
};

export const actionTypes = {
  ...sharedReducerHookActionTypes,
};

export const customPagesReducer = (
  state: CustomPagesStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};

// --- Canvas Page ---

export interface CanvasPageStateType {
  locationIds: string[];
  customizableDivs: CustomizableDivsType[];
  timeData: { id: string; value: string }[];
  hasSavedData: boolean;
  loading: boolean;
  isEditable: boolean;
  pageName: string;
}

export interface CustomDivConfigDetails {
  locationId: string;
  displayType: string;
  sensor: string;
  hours: string;
  color: string;
  refreshInterval: string;
  freeText: string;
  textSize: string;
}

export interface CustomizableDivsType {
  id: string;
  isDraggable: boolean;
  configDetails: CustomDivConfigDetails;
  dragPosition?: { x: number; y: number };
  dimensions?: { width: number; height: number };
  isConfigVisible: boolean;
  isColorVisible: boolean;
  isLoading: boolean;
  chartData: Partial<ChartListType>;
  gaugeData: Partial<GaugeListType>;
}

export const displayTypes = [
  {
    text: 'Chart',
    value: 'chart',
  },
  {
    text: 'Gauge',
    value: 'gauge',
  },
  {
    text: 'Text',
    value: 'freeText',
  },
];

export const sensorTypes = [
  {
    text: 'CO2',
    value: 'CO2',
  },
  {
    text: 'HUM',
    value: 'HUM',
  },
  {
    text: 'TEMP',
    value: 'TEMP',
  },
  {
    text: 'CO',
    value: 'CO',
  },
  {
    text: 'DP',
    value: 'DP',
  },
  {
    text: 'NO2',
    value: 'NO2',
  },
  {
    text: 'O3',
    value: 'O3',
  },
  {
    text: 'PM10',
    value: 'PM10',
  },
  {
    text: 'PM2.5',
    value: 'PM2.5',
  },
  {
    text: 'RH',
    value: 'RH',
  },
];

export const timeList = [
  {
    value: '3',
    text: '3 Hours',
  },
  {
    value: '24',
    text: '24 Hours',
  },
  {
    value: '48',
    text: '48 Hours',
  },
  {
    value: '120',
    text: '5 Days',
  },
];

export const resizableCursorTypes = [
  'col-resize',
  'se-resize',
  'row-resize',
  'ne-resize',
  'nw-resize',
  'sw-resize',
];

// --- Canvas Page --- end
