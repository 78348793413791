import { FormInputType } from '../../../@types';

export const initFormElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      name: 'userName',
      type: 'text',
      placeholder: 'Enter username',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Username is required',
    label: 'Username',
  },
  password: {
    elementType: 'input',
    elementConfig: {
      name: 'password',
      type: 'password',
      placeholder: 'Enter password',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Password is required',
    label: 'Password',
  },
};

export type StateType = {
  formElements: FormElementsType;
  loading: boolean;
  isButtonDisabled: boolean;
  showChangePassModal: boolean;
  showResetPassModal: boolean;
};

export type FormElementsType = {
  userName: FormInputType;
  password: FormInputType;
};
