import { Fragment, PureComponent, ReactNode } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  PopupProps,
  MarkerProps,
  MapContainerProps,
} from 'react-leaflet';
import Leaflet from 'leaflet';

import 'leaflet/dist/leaflet.css';

import {
  getBoundsUnproject,
  getLeafletIcon,
} from '../../utils/leaflet-helpers';

import { FloorPlanMapDetailsType } from '../../@types';
import { Col, Row } from 'antd';

interface PropsType {
  mapDetails: FloorPlanMapDetailsType;
  zoom?: number;
  mapMarkerRender?: () => ReactNode;
  mapPopup?: () => ReactNode;
  mapPopupContent?: (params: { lat: number; lng: number }) => ReactNode;
  markerIconColor?: 'amber' | 'red' | 'green' | 'grey' | 'blue';
  popupProps?: Partial<PopupProps>;
  markerProps?: Partial<MarkerProps>;
  mapContainerProps?: Partial<MapContainerProps>;
  onMapMount?: (map: Leaflet.Map, zoom: number) => void;
  center?: { lat: number; lng: number };
  polygonRender?: ReactNode;
}

interface StateType {
  mapBounds?: Leaflet.LatLngBounds;
  mapInstance?: Leaflet.Map;
  mapCenter: { lat: number; lng: number };
}

const customCRS = (Leaflet as any).extend({}, Leaflet.CRS, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(1, 0, 1, 0),
});

const unprojectZoom = 4;

class FloorPlanMap extends PureComponent<PropsType, StateType> {
  _isMounted = false;

  constructor(props: PropsType) {
    super(props);

    this.state = {
      mapBounds: undefined,
      mapInstance: undefined,
      mapCenter: Leaflet.latLng(0, 0),
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { center } = this.props;
    if (center && center?.lat !== prevProps?.center?.lat) {
      this.setState({ mapCenter: center });
    }
  }

  handleMap = (map: Leaflet.Map) => {
    const { mapDetails, onMapMount, center } = this.props;
    if (map) {
      onMapMount?.(map, unprojectZoom);
    }

    if (map && mapDetails?.height && mapDetails?.width) {
      const bounds = getBoundsUnproject(
        map,
        mapDetails.height,
        mapDetails.width,
        unprojectZoom
      );

      if (bounds) {
        map.fitBounds(bounds);
      }

      // map.setZoom(unprojectZoom);
      this._isMounted &&
        this.setState(
          {
            mapBounds: bounds,
            mapInstance: map,
            mapCenter: center || {
              lat: map.getBounds().getCenter().lat,
              lng: map.getBounds().getCenter().lng,
            },
          },
          () => {
            // map.setZoom(6);
          }
        );
    }
  };

  render() {
    const {
      mapDetails,
      mapMarkerRender,
      mapPopup,
      mapPopupContent,
      markerIconColor,
      markerProps,
      popupProps,
      mapContainerProps,
      polygonRender,
    } = this.props;
    const { mapBounds, mapCenter } = this.state;
    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image || ''
    )}`;

    return (
      <Fragment>
        <MapContainer
          {...mapContainerProps}
          whenCreated={this.handleMap}
          zoom={unprojectZoom}
          center={mapCenter}
          crs={customCRS}>
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}

          {polygonRender}

          {mapMarkerRender ? (
            mapMarkerRender()
          ) : (
            <Marker
              {...markerProps}
              position={mapCenter}
              icon={getLeafletIcon(markerIconColor || 'blue')}>
              {mapPopup ? (
                mapPopup()
              ) : (
                <Popup {...popupProps}>
                  {mapPopupContent ? (
                    mapPopupContent({
                      lat: mapCenter?.lat || 0,
                      lng: mapCenter?.lng || 0,
                    })
                  ) : (
                    <Row>
                      <Col xs={24}>Latitude: {mapCenter.lat}</Col>
                      <Col xs={24}> Longitude: {mapCenter.lng} </Col>
                    </Row>
                  )}
                </Popup>
              )}
            </Marker>
          )}
        </MapContainer>
      </Fragment>
    );
  }
}

export default FloorPlanMap;
