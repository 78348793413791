import { settingsDialogInitFormElements } from '../../components-shared/SettingsModal/helpers';
import { ReduxActionType, ReduxLocationStoreType } from '../../@types';
import {
  SAVE_FILTER_CHECKBOX_DATA,
  SAVE_FLOOR_PLAN_DETAILS,
  SEARCH_DATA,
  SETTINGS_DIALOG,
} from '../actionTypes/location.types';

const initialState: ReduxLocationStoreType = {
  filterCheckBoxData: {
    checkBoxDetails: {},
    isAllSelected: true,
  },
  searchData: '',
  settingsDialogFormData: { ...settingsDialogInitFormElements },
  floorPlanDetails: {
    floorPlanID: '',
    assetID: '',
  },
};

function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case SAVE_FILTER_CHECKBOX_DATA: {
      return {
        ...state,
        filterCheckBoxData: {
          checkBoxDetails:
            action?.payload?.filterCheckBoxData?.checkBoxDetails ||
            state.filterCheckBoxData.checkBoxDetails,
          isAllSelected:
            action?.payload?.filterCheckBoxData?.isAllSelected !== undefined
              ? action?.payload?.filterCheckBoxData?.isAllSelected
              : state.filterCheckBoxData.isAllSelected,
        },
      };
    }

    case SEARCH_DATA: {
      return {
        ...state,
        searchData: action?.payload?.searchData,
      };
    }

    case SETTINGS_DIALOG: {
      return {
        ...state,
        settingsDialogFormData:
          action?.payload?.settingsDialogFormData ||
          state.settingsDialogFormData,
      };
    }

    case SAVE_FLOOR_PLAN_DETAILS: {
      return {
        ...state,
        floorPlanDetails: {
          ...state.floorPlanDetails,
          floorPlanID: action.payload?.floorPlanID,
          assetID: action.payload?.assetID,
        },
      };
    }

    default:
      return { ...state };
  }
}

export default reducer;
