import { Dispatch, Fragment, useReducer, useEffect } from 'react';
import { Row, Col, Button, Modal, Input, message } from 'antd';
import { v4 } from 'uuid';
import { Fab } from 'react-tiny-fab';
import {
  actionTypes,
  customPagesInitState,
  customPagesReducer,
  CustomPagesStateType,
} from '../components/CustomPages/helpers';
import AntdCard from '../components-shared/AntdCard';

import { AxiosHttpResponseType, UserDataType } from '../@types/api-types';
import { RouteComponentProps } from 'react-router-dom';
import { customPageRoutes } from '../routes/routes-list';
import { ReducerHookActionType } from '../@types';
import useHttp from '../hooks/use-http';
import { displaysApi } from '../api-services/api-list';
import AntdCoverSpinner from '../components-shared/AntdCoverSpinner';
import cssStyles from '../components/CustomPages/styles/customPages.module.scss';

import 'react-tiny-fab/dist/styles.css';

type PropsType = RouteComponentProps & {
  userData: Partial<UserDataType>;
};

const CustomPages = ({ userData, history }: PropsType) => {
  const { sendRequest, isLoading } = useHttp(true);
  const [state, dispatchToState]: [
    state: CustomPagesStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(customPagesReducer, customPagesInitState);

  const { selectedPage, displayLists, showModal, createPageInput } = state;

  useEffect(() => {
    if (userData.token && displayLists.length === 0) {
      const handleResponse = (response: AxiosHttpResponseType) => {
        let temp = response.data.data;
        if (temp && temp.length > 0) {
          temp = temp.map((el: any) => ({ id: v4(), name: el.name }));
          dispatchToState({
            type: actionTypes.setState,
            payload: { displayLists: temp },
          });
        }
      };

      const apiDetails = displaysApi.getDisplays();

      sendRequest(
        { ...apiDetails, storeToken: userData.token },
        handleResponse
      );
    }
  }, [displayLists.length, sendRequest, userData.token]);

  const onViewClick = (name: string) => {
    history.push({
      pathname: customPageRoutes.canvas({ pageName: name }),
      state: { isEditable: false, pageName: name },
    });
  };

  const onEditClick = (name: string) => {
    history.push({
      pathname: customPageRoutes.canvas({ pageName: name }),
      state: { isEditable: true, pageName: name },
    });
  };

  const openModal = () => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { showModal: true },
    });
  };

  const closeModal = () => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { showModal: false },
    });
  };

  const onModalOkay = () => {
    if (!createPageInput) {
      message.warning('Fill the fields correctly!');
      return;
    }

    history.push({
      pathname: customPageRoutes.canvas({ pageName: createPageInput }),
      state: { isEditable: true, pageName: createPageInput },
    });
  };

  return (
    <Fragment>
      <AntdCoverSpinner active={isLoading}>
        <Row className="p-3">
          <Col xs={24} style={{ background: '#fff', minHeight: '100vh' }}>
            <Row className="p-4" gutter={[16, 16]}>
              {displayLists.map((el) => {
                return (
                  <Fragment key={el.id}>
                    <Col
                      onMouseEnter={() =>
                        dispatchToState({
                          type: actionTypes.setState,
                          payload: { selectedPage: el.id },
                        })
                      }
                      onMouseLeave={() =>
                        dispatchToState({
                          type: actionTypes.setState,
                          payload: { selectedPage: '' },
                        })
                      }>
                      <AntdCard elevate className={cssStyles.card}>
                        <Row>
                          <Col xs={24}>
                            <span>{el.name}</span>
                            {selectedPage === el.id && (
                              <Fragment>
                                <Button
                                  type="primary"
                                  onClick={() => onViewClick(el.name)}>
                                  View
                                </Button>
                                <Button
                                  type="primary"
                                  onClick={() => onEditClick(el.name)}>
                                  Edit
                                </Button>
                              </Fragment>
                            )}
                          </Col>
                        </Row>
                      </AntdCard>
                    </Col>
                  </Fragment>
                );
              })}
            </Row>
          </Col>
        </Row>

        <Fab
          mainButtonStyles={{
            backgroundColor: '#1890ff',
            color: '#fff',
            fontSize: 24,
          }}
          // style={position}
          icon={'+'}
          event={'click'}
          alwaysShowTitle={false}
          onClick={openModal}></Fab>
      </AntdCoverSpinner>

      <Modal
        closable={false}
        visible={showModal}
        onCancel={closeModal}
        onOk={onModalOkay}>
        <Row justify="center">
          <Col>
            <Input
              placeholder="Enter page name"
              value={createPageInput}
              onChange={(e) =>
                dispatchToState({
                  type: actionTypes.setState,
                  payload: { createPageInput: e.currentTarget.value },
                })
              }
            />
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default CustomPages;
