import {
  Fragment,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { Card, Col, Row } from 'antd';
import Leaflet from 'leaflet';
import { CircleMarker, CircleMarkerProps, Polygon } from 'react-leaflet';

import FloorPlanMap from '../../../components-shared/FloorPlanMap';
import { DefaultObjectType, FloorPlanMapDetailsType } from '../../../@types';
import { FloorPlanLocationsWithPosition } from '../helpers';
import cssStyles from '../styles/floorPlans.module.scss';
import {
  LatLngCoordinatesType,
  MarkersListType,
} from '../../../@types/api-types';
import { getObjectKeysInUpperCase } from '../../../utils';

type PropsType = {
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  mapDetails: FloorPlanMapDetailsType;
  onLocationSelect: (value: string) => void;
  selectedLocation: string;
  loading: boolean;
  selectedPhenoms: string[];
  mapInstance: Leaflet.Map | undefined;
  setMapInstance: Dispatch<SetStateAction<Leaflet.Map | undefined>>;
  unprojectZoom: number | undefined;
  setUnprojectZoom: Dispatch<SetStateAction<number | undefined>>;
};

interface PolygonsListType {
  locationId: string;
  color?: string;
  outerPolygonLatLng?: LatLngCoordinatesType[];
  innerPolygonsLatLng?: LatLngCoordinatesType[][];
  markers?: MarkersListType[];
  latestData?: {
    [k: string]: number;
  };
  phenomValue?: number;
}

const MapSection = ({
  floorPlanLocationList,
  mapDetails,
  onLocationSelect,
  selectedLocation,
  loading,
  selectedPhenoms,
  setMapInstance,
  setUnprojectZoom,
}: PropsType) => {
  const [polygonsList, setPolygonsList] = useState<(PolygonsListType | null)[]>(
    []
  );
  const [singleSelectedPhenom, setSingleSelectedPhenom] = useState<string>(
    selectedPhenoms.length > 0 ? selectedPhenoms[0] : ''
  );

  useEffect(() => {
    if (selectedPhenoms.length === 1) {
      setSingleSelectedPhenom(selectedPhenoms[0]);
    } else {
      setSingleSelectedPhenom('');
      setPolygonsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhenoms.length]);

  useEffect(() => {
    if (singleSelectedPhenom && floorPlanLocationList.length > 0) {
      const tempPolygons = floorPlanLocationList
        .map((el) => {
          if (
            el.outerPolygonLatLng &&
            el.innerPolygonsLatLng &&
            el.locationID
          ) {
            let phenomValues: DefaultObjectType | undefined;
            if (el?.latestData?.Dnum) {
              phenomValues = getObjectKeysInUpperCase(el.latestData.Dnum);
            }

            let actualValue: number | undefined;
            if (phenomValues) {
              actualValue = phenomValues[singleSelectedPhenom?.toUpperCase?.()];
            }

            const foundPhenom = el.sensorSpecs.find(
              (elem) =>
                elem.shortName.toUpperCase() ===
                singleSelectedPhenom?.toUpperCase?.()
            );

            if (foundPhenom && actualValue) {
              for (let index = 0; index < foundPhenom.markers.length; index++) {
                if (actualValue <= foundPhenom.markers[index].value) {
                  return {
                    locationId: el.locationID,
                    color: foundPhenom.markers[index].colour,
                    outerPolygonLatLng: el.outerPolygonLatLng,
                    innerPolygonsLatLng: el.innerPolygonsLatLng,
                    markers: foundPhenom.markers,
                    latestData: el.latestData.Dnum,
                    phenomValue: actualValue,
                  };
                }
              }
            } else {
              return {
                locationId: el.locationID,
                outerPolygonLatLng: el.outerPolygonLatLng,
                innerPolygonsLatLng: el.innerPolygonsLatLng,
                markers: foundPhenom?.markers ?? undefined,
                latestData: el.latestData.Dnum,
                phenomValue: actualValue ?? undefined,
              };
            }
          }

          return null;
        })
        .filter((el) => el);

      if (tempPolygons.length > 0) {
        setPolygonsList(tempPolygons);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorPlanLocationList.length, singleSelectedPhenom, selectedLocation]);

  const onMapMount = useCallback(
    (map, zoom) => {
      setMapInstance(map);
      setUnprojectZoom(zoom);
    },
    [setMapInstance, setUnprojectZoom]
  );

  return (
    <Fragment>
      <Col
        xs={24}
        md={16}
        className="pl-md-2 pt-3 pt-md-0"
        style={{ height: '800px' }}>
        <Card
          className="h-100"
          bodyStyle={{ height: '100%' }}
          loading={loading}>
          <Row className="h-100" align="middle" justify="center">
            <Col xs={24} className="h-100">
              {mapDetails.image && (
                <FloorPlanMap
                  mapDetails={mapDetails}
                  mapContainerProps={{ className: cssStyles.map }}
                  polygonRender={
                    <PolygonsRender
                      selectedPhenoms={selectedPhenoms}
                      polygonsList={polygonsList}
                    />
                  }
                  mapMarkerRender={() => (
                    <MapMarker
                      floorPlanLocations={floorPlanLocationList}
                      selectedLocation={selectedLocation}
                      onLocationSelect={onLocationSelect}
                    />
                  )}
                  onMapMount={onMapMount}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Fragment>
  );
};

export default MapSection;

const PolygonsRender = ({
  selectedPhenoms,
  polygonsList,
}: {
  selectedPhenoms: string[];
  polygonsList: (PolygonsListType | null)[];
}) => {
  return (
    <Fragment>
      {polygonsList.length > 0 &&
        polygonsList.map((el, idx) => {
          return (
            <Fragment key={el?.locationId ?? idx}>
              {selectedPhenoms.length === 1 && (
                <Fragment>
                  {el?.innerPolygonsLatLng &&
                    el.innerPolygonsLatLng.length > 0 &&
                    el.innerPolygonsLatLng.map((elem, idx) => {
                      return <Polygon key={idx} positions={elem} />;
                    })}
                  {el?.outerPolygonLatLng &&
                    el.outerPolygonLatLng.length > 0 && (
                      <Polygon
                        positions={el.outerPolygonLatLng}
                        color={el.color}
                      />
                    )}
                </Fragment>
              )}
            </Fragment>
          );
        })}
    </Fragment>
  );
};

const MapMarker = ({
  floorPlanLocations,
  selectedLocation,
  onLocationSelect,
}: {
  floorPlanLocations: FloorPlanLocationsWithPosition[];
  selectedLocation: string;
  onLocationSelect: (value: string) => void;
}) => {
  return (
    <Fragment>
      {floorPlanLocations.length > 0 &&
        floorPlanLocations.map((el) => {
          let pathOptionsProps: Partial<CircleMarkerProps> = {
            fillColor: el.colorCode,
            fillOpacity: 0.8,
            color: undefined,
          };

          if (selectedLocation === el.locationID) {
            pathOptionsProps.fillOpacity = 0.7;
            pathOptionsProps.color = '#098bf4';
            pathOptionsProps.weight = 3;
            pathOptionsProps.opacity = 1;
          }

          return (
            <Fragment key={el.uuid}>
              <CircleMarker
                pathOptions={{ ...pathOptionsProps }}
                eventHandlers={{ click: () => onLocationSelect(el.locationID) }}
                center={[
                  el.position?.lat || 0,
                  el.position?.lng || 0,
                ]}></CircleMarker>
            </Fragment>
          );
        })}
    </Fragment>
  );
};
