import { notification } from 'antd';
import { capitalizeFirstLetter } from '.';
import { NotificationDetailsType } from '../@types';

export function handleNotification(
  type: 'success' | 'error' | 'warning' | 'info',
  details: NotificationDetailsType,
  options?: { duration?: number; onClose?: () => void }
) {
  const duration = options?.duration || 1.5;
  const message = capitalizeFirstLetter(type);
  let description = '';
  if (details?.message && !skipErrorMessagesList.includes(details.message)) {
    description = details.message;
  }

  notification[type]({
    message: message,
    description: description,
    duration: duration,
    onClose: options?.onClose,
  });
}

const skipErrorMessagesList = [`Cannot read property 'message' of null`];
