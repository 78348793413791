import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Fragment } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { ChartListType } from '../../../@types';

type PropsType = {
  charts: ChartListType[];
  loading: boolean;
};

const ChartSection = ({ charts, loading }: PropsType) => {
  const screens = useBreakpoint();

  return (
    <Fragment>
      <Row className="chart-section" justify="center" align="middle">
        <Col xl={24}>
          {charts.length > 0 ? (
            <Fragment>
              {charts.map((el) => {
                const shortName = el.shortName.replace(
                  /(\d+)/g,
                  '<sub >$1</sub>'
                );
                return (
                  <Fragment key={el.shortName}>
                    <Row
                      className="pt-3 px-3 px-xl-0"
                      style={{ height: screens.xl ? '22vh' : undefined }}>
                      <Col xs={24} xl={4} className="text">
                        <Row>
                          <Col className="text-center">
                            <div>{el.label}</div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: shortName,
                              }}></div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={24}
                        xl={20}
                        style={{ height: screens.xl ? '100%' : undefined }}
                        className="chart">
                        <LineChart
                          data={{ datasets: [el] }}
                          options={{
                            scales: {
                              xAxes: [
                                {
                                  type: 'time',
                                  time: {
                                    unit: 'hour',
                                  },
                                  ticks: {
                                    fontSize: !screens.xl ? 14 : 20,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  ticks: {
                                    fontSize: !screens.xl ? 14 : 20,
                                    beginAtZero: true,
                                  },
                                },
                              ],
                            },
                            // legend: { labels: { fontSize: 10 } },
                            legend: {
                              display: false,
                            },
                            responsive: true,
                          }}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                );
              })}
            </Fragment>
          ) : (
            charts.length === 0 &&
            !loading && (
              <div
                className="text-center"
                style={{
                  fontSize: '16px',
                  fontStyle: 'italic',
                  color: 'rgb(54, 114, 248)',
                }}>
                No data within the last 24 hours
              </div>
            )
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default ChartSection;
