import update from 'immutability-helper';

import { FormInputType, ReducerHookActionType } from '../../../@types';
import { ApiKeysListType } from '../../../@types/api-types';
import { sharedReducerHookActionTypes } from '../../../utils/constants';

export const initFormElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      name: 'userName',
      type: 'text',
      placeholder: 'Enter username',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Username is required',
    label: 'Username',
    disabled: true,
  },
  userEmail: {
    elementType: 'input',
    elementConfig: {
      name: 'userEmail',
      type: 'email',
      placeholder: 'Enter email',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Email is required',
    label: 'Email',
  },
  userPhone: {
    elementType: 'input',
    elementConfig: {
      name: 'userPhone',
      type: 'text',
      placeholder: 'Enter phone',
    },
    value: '',
    validation: {
      // required: true,
      isNumeric: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Phone is required',
    label: 'Phone',
  },
  latitude: {
    elementType: 'input',
    elementConfig: {
      name: 'latitude',
      type: 'text',
      placeholder: 'Enter latitude',
    },
    value: '',
    validation: {
      // required: true,
      isNegativeFloat: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Latitude is required',
    label: 'Latitude',
    colValue: 10,
  },
  longitude: {
    elementType: 'input',
    elementConfig: {
      name: 'longitude',
      type: 'text',
      placeholder: 'Enter longitude',
    },
    value: '',
    validation: {
      // required: true,
      isNegativeFloat: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Longitude is required',
    label: 'Longitude',
    colValue: 10,
  },
};

export const initState: StateType = {
  formElements: { ...initFormElements },
  initLat: '',
  initLng: '',
  showMapModal: false,
  showChangePass: false,
  showApiKeysModal: false,
  mapZoom: 12,
};

export type FormElementsType = {
  userName: FormInputType;
  userEmail: FormInputType;
  userPhone: FormInputType;
  latitude: FormInputType;
  longitude: FormInputType;
};

export type StateType = {
  formElements: FormElementsType;
  initLat: string;
  initLng: string;
  showMapModal: boolean;
  showChangePass: boolean;
  showApiKeysModal: boolean;
  mapZoom: number;
};

export const actionTypes = {
  ...sharedReducerHookActionTypes,
  setChangePass: 'SET_CHANGE_PASS',
  setApiKeys: 'SET_API_KEYS',
  setAddApiKeyModal: 'SET_ADD_API_KEY_MODAL',
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case actionTypes.setState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    case actionTypes.setChangePass: {
      const updatedState = update(state, {
        showChangePass: { $set: !state.showChangePass },
      });

      return { ...updatedState };
    }

    case actionTypes.setApiKeys: {
      const updatedState = update(state, {
        showApiKeysModal: { $set: !state.showApiKeysModal },
      });

      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};

export const apiKeysModalInitState: ApiKeysModalStateType = {
  apiKeysList: [],
  newApiKey: '',
  showAddModal: false,
};

export type ApiKeysModalStateType = {
  apiKeysList: ApiKeysListType[];
  newApiKey: string;
  showAddModal: boolean;
};

export const apiKeysModalReducer = (
  state: ApiKeysModalStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    case actionTypes.setAddApiKeyModal: {
      const updatedState = update(state, {
        showAddModal: { $set: !state.showAddModal },
        newApiKey: { $set: '' },
      });

      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};
