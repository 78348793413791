import { useRef, useEffect, useCallback } from 'react';

const useMounted = () => {
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setIsMounted = useCallback((value?: boolean) => {
    isMounted.current = value || false;
  }, []);

  return { isMounted: isMounted.current, setIsMounted };
};

export default useMounted;
