import { Fragment, Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import ErrorBoundary from './components-shared/ErrorBoundary';
import { authLogout, getUserByToken } from './redux/actions/auth.actions';
import Routes from './routes';
import { ReduxStoreType } from './@types';
import { handleNotification } from './utils/notification-handler';
import { setInLocal } from './utils/manage-storage';

type PropsType = PropsFromRedux & RouteComponentProps;

class App extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);

    const { location } = props;
    let paramToken: string | undefined;
    const search = location?.search;
    if (search) {
      const params = new URLSearchParams(search);
      const tempParamToken = params?.get('paramToken');
      if (tempParamToken) {
        paramToken = tempParamToken;
        if (paramToken) {
          setInLocal('token', paramToken);
        }
      }
    }
  }
  componentDidMount() {
    const { userData, getUserByToken } = this.props;

    if (!userData?.token) {
      getUserByToken();
    }
  }

  componentDidUpdate() {
    const { error, authLogout } = this.props;
    if (error?.status === 'invalidTokenError') {
      handleNotification('error', { message: error.message });
      authLogout();
    } else if (error?.status === 'error') {
      handleNotification('error', { message: error.message });
    }
  }

  render() {
    return (
      <Fragment>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStoreType) {
  return {
    userData: state.auth.userData,
    error: state.auth.error,
  };
}

const mapDispatch = {
  getUserByToken: getUserByToken,
  authLogout: authLogout,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(App));
