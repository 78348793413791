import { ReduxActionType, ReduxAuthStoreType } from '../../@types';
import {
  AUTH_SUCCESS,
  LOGOUT,
  TOKEN_SUCCESS,
  UPDATE_TOKEN,
  ERRORED,
} from '../actionTypes/auth.types';

const initialState: ReduxAuthStoreType = {
  userData: {},
  loading: false,
  error: {},
};

function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        userData: {},
        loading: false,
        error: {},
      };
    }

    case TOKEN_SUCCESS: {
      return {
        ...state,
        userData: action?.payload?.userData || {},
      };
    }

    case UPDATE_TOKEN: {
      return {
        ...state,
        userData: { ...state.userData, token: action.payload },
      };
    }

    case AUTH_SUCCESS: {
      if (action.payload && Object.keys(action.payload).length > 0) {
        return {
          ...state,
          ...action.payload,
        };
      }
      return {
        ...state,
      };
    }

    case ERRORED: {
      return { ...state, error: action.payload.errorData };
    }

    default:
      return { ...state };
  }
}

export default reducer;
