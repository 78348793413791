import { Fragment, PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import update from 'immutability-helper';

import { saveSettingsDialog } from '../../redux/actions/location.actions';
import { ReduxStoreType } from '../../@types';
import { checkValidation } from '../../utils/validation';
import { SettingsDialogFormElementsType } from './helpers';
import { getSingleInputElement } from '../../utils/get-input-element';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { locationRoutes } from '../../routes/routes-list';

type PropsType = PropsFromRedux &
  RouteComponentProps & {
    openModal?: () => void;
    showBackButton?: boolean;
    showModal: boolean;
    closeModal: () => void;
    onSaveSettingsDialog: () => void;
  };

type StateType = {
  isOkBtnDisabled: boolean;
  formElements: SettingsDialogFormElementsType;
};

class SettingsModal extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      isOkBtnDisabled: false,
      formElements: props.settingsDialogFormData,
    };
  }

  inputChangedHandler = (
    name: keyof SettingsDialogFormElementsType,
    value: any,
  ) => {
    const { settingsDialogFormData } = this.props;

    let tempFormElements = { ...settingsDialogFormData };
    let key: keyof typeof tempFormElements;

    if (name) {
      if (name !== 'transitionType') {
        value = Number(value);
      }
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      let isValid = true;
      for (key in tempFormElements) {
        if (!tempFormElements[key].valid) {
          isValid = false;
        }
      }

      this.setState({
        isOkBtnDisabled: !isValid,
        formElements: tempFormElements,
      });
    }
  };

  handleRedirect = () => {
    const { history } = this.props;
    history.push(locationRoutes.list());
  };

  onOkay = () => {
    const { saveSettingsDialog, onSaveSettingsDialog } = this.props;
    const { formElements } = this.state;

    saveSettingsDialog({ settingsDialogFormData: formElements });

    onSaveSettingsDialog();
  };

  onCancel = () => {
    const { settingsDialogFormData, closeModal } = this.props;

    this.setState({ formElements: settingsDialogFormData });

    closeModal();
  };

  render() {
    const { showModal, showBackButton } = this.props;

    const { formElements } = this.state;

    const single = getSingleInputElement({
      formElements: formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 5],
    });

    return (
      <Fragment>
        <Modal
          closable={false}
          visible={showModal}
          onCancel={this.onCancel}
          footer={[
            showBackButton && (
              <Button key="3" onClick={this.handleRedirect}>
                Return to List
              </Button>
            ),
            <Button key="2" onClick={this.onCancel}>
              Cancel
            </Button>,
            <Button key="1" type="primary" onClick={this.onOkay}>
              Ok
            </Button>,
          ]}>
          {single}
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatch = {
  saveSettingsDialog: saveSettingsDialog,
};

function mapStateToProps(state: ReduxStoreType) {
  return {
    settingsDialogFormData: state.location.settingsDialogFormData,
  };
}

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(SettingsModal));
