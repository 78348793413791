import { Col, Menu, Row } from 'antd';
import { FaMapMarkerAlt, FaTimes, FaUser } from 'react-icons/fa';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { GiPositionMarker } from 'react-icons/gi';
import { BiCustomize } from 'react-icons/bi';
// import { MdMyLocation } from 'react-icons/md';
import Sider from 'antd/lib/layout/Sider';
import {
  Fragment,
  Key,
  MouseEvent,
  ReactInstance,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import Layout from 'antd/lib/layout/layout';
import { useDispatch, useSelector } from 'react-redux';

import cssStyles from './styles/appLayout.module.scss';
import { ReduxStoreType } from '../../@types';
import { authLogout } from '../../redux/actions/auth.actions';
import { useHistory, useLocation } from 'react-router-dom';
import {
  authRoutes,
  customPageRoutes,
  locationRoutes,
} from '../../routes/routes-list';

// import { ReactComponent as FloorPlanIcon } from '../../assets/images/map/floorplan-svg.svg';
import { ReactComponent as FloorPlanIconBlack } from '../../assets/svg-icons/floorplanxmall.svg';
import { ReactComponent as FloorPlanIconBlue } from '../../assets/svg-icons/floorplanxmallblue.svg';

const { SubMenu } = Menu;

type PropsType = {
  children?: ReactNode;
};

function AppLayout({ children }: PropsType) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState<string>(
    getCurrentSelectedKey(location.pathname)
  );
  const userData = useSelector((state: ReduxStoreType) => state.auth.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setSelectedKey(getCurrentSelectedKey(location.pathname));
  }, [location.pathname]);

  function handleRedirect(path: string) {
    history.push(path);
  }

  function onMenuClick({
    key,
  }: {
    key: Key;
    keyPath: Key[];
    item: ReactInstance;
    domEvent: MouseEvent<HTMLElement>;
  }) {
    setSelectedKey((prev) => (key === '0' ? prev : key.toString()));
  }

  let showSidebar = true;
  let showTopbar = true;
  if (location.pathname.includes('carousel')) {
    showSidebar = false;
    showTopbar = false;
  }

  if (location.pathname.includes('kiosk')) {
    showSidebar = false;
    showTopbar = false;
  }

  let appLayoutContent = null;

  if (showSidebar && showTopbar) {
    appLayoutContent = (
      <Layout>
        <Sider
          theme="light"
          defaultCollapsed={false}
          className={cssStyles.root}
          collapsed={collapsed}
          collapsible
          trigger={null}>
          <Menu
            onClick={onMenuClick}
            selectedKeys={[selectedKey]}
            selectable={false}
            mode="inline">
            <Menu.Item
              key="0"
              icon={
                collapsed ? (
                  <HiOutlineMenuAlt2
                    style={{ cursor: 'pointer' }}
                    size="1.5em"
                    onClick={() => setCollapsed((prev) => !prev)}
                  />
                ) : (
                  <FaTimes
                    style={{ cursor: 'pointer' }}
                    size="1.5em"
                    onClick={() => setCollapsed((prev) => !prev)}
                  />
                )
              }
              className={cssStyles.iconMenu}
              style={{ justifyContent: collapsed ? 'center' : 'flex-end' }}
            />

            <Menu.Item
              key="1"
              icon={<FaMapMarkerAlt />}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(locationRoutes.list())}>
              {!collapsed && 'Locations'}
            </Menu.Item>

            <Menu.Item
              key="2"
              icon={
                selectedKey === '2' ? (
                  <FloorPlanIconBlue />
                ) : (
                  <FloorPlanIconBlack />
                )
              }
              className={collapsed ? 'floorPlanIconCollapsed' : 'floorPlanIcon'}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(locationRoutes.floorPlan())}>
              {!collapsed && 'Floor Plans'}
            </Menu.Item>

            <Menu.Item
              key="3"
              icon={
                <Fragment>
                  <GiPositionMarker />
                  <GiPositionMarker />
                </Fragment>
              }
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(locationRoutes.multipleCharts())}>
              <span
                style={{
                  fontSize: '12px',
                  marginLeft: collapsed ? undefined : '6px',
                }}>
                {!collapsed && 'Multi-Site Graphs'}
              </span>
            </Menu.Item>

            <Menu.Item
              key="4"
              icon={<FaUser />}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(authRoutes.profileDetails())}>
              {!collapsed && 'User'}
            </Menu.Item>

            <Menu.Item
              key="5"
              icon={<BiCustomize size="1.4em" />}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(customPageRoutes.root())}>
              {!collapsed && 'Custom Pages'}
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout className={cssStyles.siteLayout}>
          <Menu mode="horizontal">
            <SubMenu
              className="antdSubmenu"
              key="SubMenu"
              icon={<IoMdArrowDropdown size="1.5em" />}
              title={userData?.username || <span>&nbsp;</span>}>
              <Menu.Item key={'1'} onClick={() => dispatch(authLogout())}>
                Logout
              </Menu.Item>
            </SubMenu>
          </Menu>

          {/* <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
              }}>
              {children}
            </Content> */}

          <Fragment>{children}</Fragment>
          {/* <Header className="siteLayout-background" style={{ padding: 0 }}>
              
            </Header> */}
        </Layout>
      </Layout>
    );
  } else if (!showSidebar && !showTopbar) {
    appLayoutContent = <Fragment>{children}</Fragment>;
  } else if (showSidebar && !showTopbar) {
    appLayoutContent = (
      <Layout>
        <Sider
          theme="light"
          defaultCollapsed={false}
          className={cssStyles.root}
          collapsed={collapsed}
          collapsible
          trigger={null}>
          <Menu
            onClick={onMenuClick}
            selectedKeys={[selectedKey]}
            selectable={false}
            mode="inline">
            <Menu.Item
              key="0"
              icon={
                collapsed ? (
                  <HiOutlineMenuAlt2
                    style={{ cursor: 'pointer' }}
                    size="1.5em"
                    onClick={() => setCollapsed((prev) => !prev)}
                  />
                ) : (
                  <FaTimes
                    style={{ cursor: 'pointer' }}
                    size="1.5em"
                    onClick={() => setCollapsed((prev) => !prev)}
                  />
                )
              }
              className={cssStyles.iconMenu}
              style={{ justifyContent: collapsed ? 'center' : 'flex-end' }}
            />

            <Menu.Item
              key="1"
              icon={<FaMapMarkerAlt />}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(locationRoutes.list())}>
              {!collapsed && 'Locations'}
            </Menu.Item>

            <Menu.Item
              key="2"
              icon={
                selectedKey === '2' ? (
                  <FloorPlanIconBlue />
                ) : (
                  <FloorPlanIconBlack />
                )
              }
              className={collapsed ? 'floorPlanIconCollapsed' : 'floorPlanIcon'}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(locationRoutes.floorPlan())}>
              {!collapsed && 'Floor Plans'}
            </Menu.Item>

            <Menu.Item
              key="3"
              icon={
                <Fragment>
                  <GiPositionMarker />
                  <GiPositionMarker />
                </Fragment>
              }
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(locationRoutes.multipleCharts())}>
              <span style={{ fontSize: '12px' }}>
                {!collapsed && 'Multi-Site Graphs'}
              </span>
            </Menu.Item>

            <Menu.Item
              key="4"
              icon={<FaUser />}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(authRoutes.profileDetails())}>
              {!collapsed && 'User'}
            </Menu.Item>

            <Menu.Item
              key="5"
              icon={<BiCustomize size="1.2em" />}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'flex-start',
              }}
              onClick={() => handleRedirect(customPageRoutes.root())}>
              {!collapsed && 'Custom Pages'}
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout className={cssStyles.siteLayout}>
          <Row className="p-3">
            <Col xs={24}></Col>
          </Row>
        </Layout>
      </Layout>
    );
  } else if (!showSidebar && showTopbar) {
    appLayoutContent = (
      <Layout className={cssStyles.siteLayout}>
        <Menu mode="horizontal">
          <SubMenu
            className="antdSubmenu"
            key="SubMenu"
            icon={<IoMdArrowDropdown size="1.5em" />}
            title={userData?.username || <span>&nbsp;</span>}>
            <Menu.Item key={'1'} onClick={() => dispatch(authLogout())}>
              Logout
            </Menu.Item>
          </SubMenu>
        </Menu>

        <Fragment>{children}</Fragment>
      </Layout>
    );
  }

  return <Fragment>{appLayoutContent}</Fragment>;
}

export default AppLayout;

function getCurrentSelectedKey(pathname: string) {
  let currentMenuKey = '';
  if (pathname === locationRoutes.list()) {
    currentMenuKey = '1';
  } else if (pathname === locationRoutes.floorPlan()) {
    currentMenuKey = '2';
  } else if (pathname === locationRoutes.multipleCharts()) {
    currentMenuKey = '3';
  } else if (pathname === authRoutes.profileDetails()) {
    currentMenuKey = '4';
  }

  return currentMenuKey;
}
