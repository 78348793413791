import { Col, Row } from 'antd';
import { Fragment, memo } from 'react';
import { aqiColors } from '../../../utils/constants';

const BoxSection = ({ overallAqiIndex }: { overallAqiIndex?: number }) => {
  let aqiBoxColor = '#FFFFFF';
  let aqiIndexVal: number | undefined;
  if (overallAqiIndex) {
    aqiIndexVal = overallAqiIndex;
    const matchedColor = aqiColors.find(
      (item) => item.value === Number(overallAqiIndex)
    );
    if (matchedColor) {
      aqiBoxColor = matchedColor.color;
    }
  }

  let textColor = '#ffffff';
  if (aqiBoxColor === '#FFFF00') {
    textColor = '#636360';
  }

  return (
    <Fragment>
      <Row className="box-section-gap" />
      <Row justify="center" align="middle" className="box-section">
        <Col xs={24} xl={16} className="title text-center">
          Air Quality Index
        </Col>
        <Col className="box" style={{ backgroundColor: aqiBoxColor }}>
          <Row justify="center" align="middle">
            <Col style={{ color: textColor }}>{aqiIndexVal}</Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default memo(BoxSection);
