export const locationRoutes = {
  list: () => {
    return `/locations`;
  },
  kiosk: (params?: { locationID: string }) => {
    if (params?.locationID) {
      return `/locations/${params?.locationID}/kiosk`;
    }
    return `/locations/:locationID/kiosk`;
  },
  dashboard: (params?: { locationID: string }) => {
    if (params?.locationID) {
      return `/locations/${params?.locationID}/dashboard`;
    }
    return `/locations/:locationID/dashboard`;
  },
  carousel: (params?: { locationID: string }) => {
    if (params?.locationID) {
      return `/locations/${params?.locationID}/carousel`;
    }
    return `/locations/:locationID/carousel`;
  },
  floorPlan: () => {
    return `/locations/floor-plan`;
  },
  multipleCharts: () => {
    return `/locations/multiple-charts`;
  },
};

export const authRoutes = {
  login: () => {
    return `/login`;
  },
  profileDetails: () => {
    return `/profile/details`;
  },
};

export const webViewRoutes = {
  gauges: (params?: { locationID: string; token: string; theme: string }) => {
    if (params?.locationID) {
      return `/webview/gauge-chart/${params?.token}/${params?.locationID}/${params?.theme}`;
    }
    return `/webview/gauge-chart/:token/:locationID/:theme`;
  },
};

export const customPageRoutes = {
  root: () => {
    return '/custom-pages';
  },
  canvas: (params?: { pageName: string }) => {
    if (params?.pageName) {
      return `/custom-pages/${params?.pageName}`;
    }
    return `/custom-pages/:pageName`;
  },
};
