import { Col, Row } from 'antd';
import { Fragment } from 'react';
import { FloorPlanMapDetailsType } from '../../../@types';
import FloorPlanMap from '../../../components-shared/FloorPlanMap/';
import LeafletMap from '../../../components-shared/LeafletMap';
import useFloorPlanMapCenter from '../../../hooks/use-floor-plan-map-center';

type PropsType = {
  mapDetails: FloorPlanMapDetailsType;
  lat: number;
  lng: number;
};

const MapSection = ({ mapDetails, lat, lng }: PropsType) => {
  const { position, setMapInstance, setUnprojectZoom } = useFloorPlanMapCenter(
    mapDetails
  );

  return (
    <Fragment>
      <Row className="map-section-gap" />

      <Row justify="center">
        <Col xs={20}>
          {mapDetails ? (
            <Fragment>
              <FloorPlanMap
                mapDetails={mapDetails}
                mapContainerProps={{ className: 'leaflet-map map' }}
                onMapMount={(map, zoom) => {
                  setMapInstance(map);
                  setUnprojectZoom(zoom);
                }}
                center={position}
              />
            </Fragment>
          ) : (
            <Fragment>
              <LeafletMap
                zoom={12}
                lat={lat}
                lng={lng}
                mapContainerProps={{ className: 'leaflet-map map' }}
              />
            </Fragment>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default MapSection;
