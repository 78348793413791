import { FormEvent, Fragment, PureComponent } from 'react';
import { FaSearch, FaFilter } from 'react-icons/fa';
import update from 'immutability-helper';

import { Dropdown, Input, Menu } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

import cssStyles from './styles/filterInput.module.scss';
import { BooleanObjectType } from '../../@types';

type PropsType = {
  dataList: string[];
  handleCheckboxFilter: (filterCheckBox: {
    isAllSelected: boolean;
    checkBoxDetails: BooleanObjectType;
  }) => void;
  handleSearchFilter: (value: string) => void;
  loading: boolean;
  filterCheckBoxData: {
    checkBoxDetails?: BooleanObjectType;
    isAllSelected: boolean;
  };
  searchData: string;
};

type StateType = {
  showDropdown: boolean;
};

class FilterInput extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      showDropdown: false,
    };
  }

  handleDropdown = () => {
    this.setState((prev) => ({ ...prev, showDropdown: !prev.showDropdown }));
  };

  onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    const { handleSearchFilter } = this.props;
    handleSearchFilter(value);
  };

  handleCheckbox = (event: CheckboxChangeEvent) => {
    const { handleCheckboxFilter, filterCheckBoxData } = this.props;
    let tempCheck = { ...filterCheckBoxData.checkBoxDetails };
    const name = event?.target?.name;
    const value = event?.target?.checked;
    let tempCheckBoxAll = filterCheckBoxData.isAllSelected;

    if (name === 'ALL') {
      for (const key in tempCheck) {
        tempCheck = update(tempCheck, { [key]: { $set: value } });
      }
      tempCheckBoxAll = value;
    }

    if (name && name !== 'ALL') {
      if (!value) {
        tempCheck = update(tempCheck, { [name]: { $set: value } });
        tempCheckBoxAll = value;
      } else {
        tempCheck = update(tempCheck, { [name]: { $set: value } });
        const allCheck = Object.keys(tempCheck).every((k) => {
          return tempCheck[k] === true;
        });
        tempCheckBoxAll = allCheck;
      }
    }

    const filterCheckBox = {
      checkBoxDetails: tempCheck,
      isAllSelected: tempCheckBoxAll,
    };

    handleCheckboxFilter(filterCheckBox);
  };

  render() {
    const { searchData, filterCheckBoxData, loading } = this.props;

    return (
      <Fragment>
        <Input
          disabled={loading}
          width="100%"
          value={searchData}
          onChange={this.onInputChange}
          size="large"
          placeholder="Search within results"
          prefix={<FaSearch />}
          suffix={
            <Dropdown
              disabled={loading}
              arrow
              overlay={
                <DropdownMenu
                  handleCheckbox={this.handleCheckbox}
                  checkBoxList={filterCheckBoxData.checkBoxDetails}
                  isCheckedAll={filterCheckBoxData.isAllSelected}
                />
              }
              trigger={['click']}
              placement="bottomRight">
              <FaFilter
                onClick={!loading ? this.handleDropdown : () => {}}
                style={{
                  cursor: !loading ? 'pointer' : 'default',
                }}
                className={`${
                  !filterCheckBoxData.isAllSelected ? cssStyles.iconColor : ''
                }`}
              />
            </Dropdown>
          }
        />
      </Fragment>
    );
  }
}

function DropdownMenu({
  handleCheckbox,
  checkBoxList,
  isCheckedAll,
}: {
  handleCheckbox: (event: CheckboxChangeEvent) => void;
  checkBoxList?: BooleanObjectType;
  isCheckedAll: boolean;
}) {
  return (
    <Fragment>
      <Menu className={`${cssStyles.menuComponent}`}>
        <Menu.Item key={'ALL'}>
          <Checkbox
            checked={isCheckedAll}
            name="ALL"
            onChange={handleCheckbox}>{`Select All`}</Checkbox>
        </Menu.Item>
        {checkBoxList &&
          Object.keys(checkBoxList).length > 0 &&
          Object.keys(checkBoxList).map((item) => {
            return (
              <Menu.Item key={item}>
                <Checkbox
                  key={item}
                  name={item}
                  onChange={handleCheckbox}
                  checked={checkBoxList[item]}>
                  {item}
                </Checkbox>
              </Menu.Item>
            );
          })}
      </Menu>
    </Fragment>
  );
}

export default FilterInput;
