import { Button, Result } from 'antd';
import { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import FloorPlans from '../pages/FloorPlans';
import LocationCarousel from '../pages/LocationCarousel';
import LocationDashboard from '../pages/LocationDashboard';
import LocationKiosk from '../pages/LocationKiosk';
import LocationList from '../pages/LocationList';
import Login from '../pages/Login';
import MultipleLocationsCharts from '../pages/MultipleLocationsCharts';
import ProfileDetails from '../pages/ProfileDetails';
import WebViewGaugeChart from '../web-view/pages/WebViewGaugeChart';
import PrivateRoute from './PrivateRoute';
import {
  authRoutes,
  customPageRoutes,
  locationRoutes,
  webViewRoutes,
} from './routes-list';
import { getFromLocal } from '../utils/manage-storage';
import LoadingPage from '../components/LoadingPage';
import { useSelector } from 'react-redux';
import { getAuthError, getUserData } from '../redux/selectors';
import ErrorPage from '../components-shared/ErrorPage';
import { ApiResponseDataType } from '../@types';
import CustomPages from '../pages/CustomPages';
import CanvasPage from '../components/CustomPages/CanvasPage';

function Routes() {
  const userData = useSelector(getUserData);
  const userToken = getFromLocal('token');
  const error = useSelector(getAuthError);

  let homeElem = <LoadingPage />;
  if (!userToken) {
    homeElem = <Redirect to={authRoutes.login()} />;
  }
  if (userData.token) {
    homeElem = <Redirect to={locationRoutes.list()} />;
  }

  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (hasError(error) ? <ErrorPage /> : homeElem)}
        />
        <Route
          exact
          path={authRoutes.login()}
          render={(renderProps) => {
            return hasError(error) ? <ErrorPage /> : <Login {...renderProps} />;
          }}
        />

        <PrivateRoute
          path={authRoutes.profileDetails()}
          component={ProfileDetails}
          userData={userData}
        />

        <PrivateRoute
          path={locationRoutes.dashboard()}
          component={LocationDashboard}
          userData={userData}
        />

        <PrivateRoute
          path={locationRoutes.carousel()}
          component={LocationCarousel}
          userData={userData}
        />

        <PrivateRoute
          path={locationRoutes.kiosk()}
          component={LocationKiosk}
          userData={userData}
        />

        <PrivateRoute
          path={locationRoutes.multipleCharts()}
          component={MultipleLocationsCharts}
          userData={userData}
        />

        <PrivateRoute
          path={locationRoutes.floorPlan()}
          component={FloorPlans}
          userData={userData}
        />

        <PrivateRoute
          path={locationRoutes.list()}
          component={LocationList}
          userData={userData}
        />

        {/* --- Sub Routes --- */}
        <PrivateRoute
          path={customPageRoutes.canvas()}
          component={CanvasPage}
          userData={userData}
        />

        {/* --- Sub Routes --- end */}

        <PrivateRoute
          path={customPageRoutes.root()}
          component={CustomPages}
          userData={userData}
        />

        {/* --- Web View --- */}
        <Route
          exact
          path={webViewRoutes.gauges()}
          component={WebViewGaugeChart}
        />

        {/* --- Web View --- end */}

        <Route
          render={(props) => (
            <Fragment>
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to={locationRoutes.list()}>
                    <Button type="primary">Back Home</Button>
                  </Link>
                }
              />
            </Fragment>
          )}
        />
      </Switch>
    </Fragment>
  );
}

export default Routes;

export const hasError = (error: ApiResponseDataType) => {
  const errorLength = error ? Object.keys(error).length : 0;
  return errorLength > 0 ? true : false;
};
