import { Dispatch, Fragment, useCallback, useEffect, useReducer } from 'react';
import { Col, Row } from 'antd';
import update from 'immutability-helper';

import useHttp from '../hooks/use-http';
import {
  reducer,
  initState,
  StateType,
  actionTypes,
  FloorPlanLocationsWithPosition,
  airQualityFilterValues,
} from '../components/FloorPlans/helpers';
import {
  ApiResponseDataType,
  ReducerHookActionType,
  ReduxStoreType,
} from '../@types';
import { RouteComponentProps } from 'react-router';
import { FloorPlanListType, UserDataType } from '../@types/api-types';
import { floorPlanApi } from '../api-services/api-list';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { apiCall } from '../api-services/api';
import { handleNotification } from '../utils/notification-handler';
import { saveFloorPlanDetails } from '../redux/actions/location.actions';
import FilterSection from '../components/FloorPlans/FilterSection';
import useMounted from '../hooks/use-isMounted';
import { updateToken } from '../redux/actions/auth.actions';
import LocationListSection from '../components/FloorPlans/LocationListSection';

type PropsType = RouteComponentProps & {
  userData: Partial<UserDataType>;
};

const FloorPlans = ({ userData }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);
  const { isLoading, sendRequest } = useHttp(true);
  const dispatch = useDispatch();
  const floorPlanIDStore = useSelector(
    (store: ReduxStoreType) => store.location.floorPlanDetails.floorPlanID
  );
  const assetIDStore = useSelector(
    (store: ReduxStoreType) => store.location.floorPlanDetails.assetID
  );
  const { isMounted } = useMounted();

  const {
    floorPlanList,
    floorPlanLocationList,
    mapDetails,
    selectedPhenoms,
    selectedAirQualities,
    phenomCollection,
    selectedLocation,
    loading,
    initFloorPlanLocationsList,
    isFloorplanChanged,
  } = state;
  const { token: storeToken } = userData;

  const fetchLocationWithAsset = useCallback(
    async (
      floorPlanID: string,
      assetID: string,
      token: string,
      dispatch: Dispatch<any>
    ) => {
      try {
        const getFloorPlanLocations = floorPlanApi.getFloorPlanLocations(
          undefined,
          {
            floorPlanID,
          }
        );

        const getAsset = floorPlanApi.getAsset(undefined, {
          assetID,
        });

        const responses = await Promise.all([
          apiCall({
            storeToken: token,
            url: getAsset.url,
            method: getAsset.method,
            contentType: getAsset.contentType,
          }),

          apiCall({
            storeToken: token,
            url: getFloorPlanLocations.url,
            method: getFloorPlanLocations.method,
            contentType: getFloorPlanLocations.contentType,
          }),
        ]);

        if (responses && responses.length > 0) {
          const tempFloorPlanLocations: FloorPlanLocationsWithPosition[] =
            responses?.[1]?.data?.data?.map(
              (el: FloorPlanLocationsWithPosition) => ({ ...el, uuid: v4() })
            );
          const tempAsset: string = responses?.[0]?.data;
          return {
            asset: tempAsset,
            floorPlanLocations: tempFloorPlanLocations,
          };
        }
      } catch (error: any) {
        handleNotification('error', error?.data);
        dispatch(updateToken(error?.data));
      }
    },
    []
  );

  useEffect(() => {
    if (storeToken && floorPlanList.length === 0 && loading) {
      const { url, method, contentType } = floorPlanApi.getFloorPlans();

      const handleResponse = async (
        response: AxiosResponse<ApiResponseDataType>
      ) => {
        const result = response.data;

        let stateData: Partial<StateType> = {};

        let assetID = assetIDStore;
        let mapWidth;
        let mapHeight;
        let floorPlanID = floorPlanIDStore || result?.data?.[0]?.floorplanID;

        if (result.data) {
          const data: FloorPlanListType[] = result.data;
          const tempFloorPlan = data.map((el) => ({ ...el, uuid: v4() }));
          stateData = update(stateData, {
            floorPlanList: { $set: tempFloorPlan },
          });

          const selectedFloorPlan = tempFloorPlan.find(
            (el) => el.floorplanID === floorPlanID
          );
          if (selectedFloorPlan) {
            floorPlanID = selectedFloorPlan.floorplanID;
            assetID = selectedFloorPlan.assetID;
            mapWidth = selectedFloorPlan.width;
            mapHeight = selectedFloorPlan.height;
          }

          let results;

          if (floorPlanID && assetID) {
            results = await fetchLocationWithAsset(
              floorPlanID,
              assetID,
              storeToken,
              dispatch
            );
          }

          if (results) {
            const { asset, floorPlanLocations } = results;
            if (asset) {
              stateData = update(stateData, {
                $merge: {
                  mapDetails: {
                    image: asset,
                    width: mapWidth,
                    height: mapHeight,
                  },
                },
              });
            }
            if (floorPlanLocations) {
              const result = onManageFloorplanLocations(floorPlanLocations);

              stateData = update(stateData, {
                floorPlanLocationList: { $set: result.floorPlanLocations },
                initFloorPlanLocationsList: { $set: result.floorPlanLocations },
                phenomCollection: { $set: result.phenomCollection },
                selectedLocation: {
                  $set: result.floorPlanLocations[0].locationID,
                },
              });
            }
          }
        }

        isMounted &&
          dispatchToState({
            type: actionTypes.setState,
            payload: { ...stateData, loading: false },
          });
        isMounted && dispatch(saveFloorPlanDetails({ floorPlanID, assetID }));
      };

      isMounted &&
        sendRequest({ url, method, contentType, storeToken }, handleResponse);
    }
  }, [
    assetIDStore,
    floorPlanIDStore,
    dispatch,
    floorPlanList.length,
    sendRequest,
    storeToken,
    isMounted,
    fetchLocationWithAsset,
    loading,
  ]);

  useEffect(() => {
    if (isFloorplanChanged) {
      const selected = floorPlanList.find(
        (el) => el.floorplanID === floorPlanIDStore
      );
      let stateData: Partial<StateType> = {};

      let results;

      if (selected && storeToken && selected?.assetID) {
        (async () => {
          results = await fetchLocationWithAsset(
            floorPlanIDStore,
            selected?.assetID,
            storeToken,
            dispatch
          );

          if (results) {
            const { asset, floorPlanLocations } = results;
            if (asset) {
              stateData = update(stateData, {
                $merge: {
                  mapDetails: {
                    image: asset,
                    width: selected.width,
                    height: selected.height,
                  },
                },
              });
            }

            if (floorPlanLocations && floorPlanLocations.length > 0) {
              const result = onManageFloorplanLocations(floorPlanLocations);
              stateData = update(stateData, {
                floorPlanLocationList: { $set: result.floorPlanLocations },
                initFloorPlanLocationsList: { $set: result.floorPlanLocations },
                phenomCollection: { $set: result.phenomCollection },
                selectedLocation: {
                  $set: result.floorPlanLocations[0].locationID,
                },
              });
            }
          }

          isMounted &&
            dispatchToState({
              type: actionTypes.setState,
              payload: {
                ...stateData,
                loading: false,
                isFloorplanChanged: false,
              },
            });
        })();
      }
    }
  }, [
    dispatch,
    fetchLocationWithAsset,
    floorPlanIDStore,
    floorPlanList,
    isFloorplanChanged,
    isMounted,
    storeToken,
  ]);

  const onFloorPlanSelect = useCallback(
    async (value: string) => {
      isMounted && dispatch(saveFloorPlanDetails({ floorPlanID: value }));
      isMounted &&
        dispatchToState({
          type: actionTypes.setState,
          payload: {
            loading: true,
            floorPlanLocationList: [],
            initFloorPlanLocationsList: [],
            isFloorplanChanged: true,
          },
        });
    },
    [dispatch, isMounted]
  );

  const onPhenomSelect = useCallback(
    (value: string[]) => {
      isMounted &&
        dispatchToState({
          type: actionTypes.setState,
          payload: { selectedPhenoms: value },
        });
    },
    [isMounted]
  );

  const onPhenomClear = useCallback(() => {
    isMounted &&
      dispatchToState({
        type: actionTypes.setState,
        payload: { selectedPhenoms: [] },
      });
  }, [isMounted]);

  const onAirQualitySelect = useCallback(
    (value: string[]) => {
      let tempData = [...initFloorPlanLocationsList];
      if (value.length > 0) {
        tempData = floorPlanLocationList.filter((el) => {
          if (el.colorName && value.includes(el.colorName)) {
            return el;
          }
          return null;
        });
      }

      isMounted &&
        dispatchToState({
          type: actionTypes.setState,
          payload: {
            selectedAirQualities: value,
            floorPlanLocationList: tempData,
          },
        });
    },
    [floorPlanLocationList, initFloorPlanLocationsList, isMounted]
  );

  const onAirQualityClear = useCallback(() => {
    isMounted &&
      dispatchToState({
        type: actionTypes.setState,
        payload: { selectedAirQualities: [] },
      });
  }, [isMounted]);

  const onLocationSelect = useCallback(
    (value: string) => {
      isMounted &&
        dispatchToState({
          type: actionTypes.setState,
          payload: { selectedLocation: value },
        });
    },
    [isMounted]
  );

  return (
    <Fragment>
      <Row className="p-3" style={{ minHeight: '100vh' }}>
        <Col xs={24}>
          <FilterSection
            loading={isLoading || loading}
            selectedFloorPlan={floorPlanIDStore}
            selectedAirQualities={selectedAirQualities}
            selectedPhenoms={selectedPhenoms}
            onFloorPlanSelect={onFloorPlanSelect}
            onAirQualitySelect={onAirQualitySelect}
            onPhenomSelect={onPhenomSelect}
            floorPlanList={floorPlanList}
            phenomCollection={phenomCollection}
            onPhenomClear={onPhenomClear}
            onAirQualityClear={onAirQualityClear}
          />

          {/* <MapSection
            loading={isLoading || loading}
            floorPlanLocationList={floorPlanLocationList}
            mapDetails={mapDetails}
            onLocationSelect={onLocationSelect}
            selectedLocation={selectedLocation}
            selectedPhenoms={selectedPhenoms}
          /> */}

          <LocationListSection
            loading={isLoading || loading}
            floorPlanLocationList={floorPlanLocationList}
            mapDetails={mapDetails}
            onLocationSelect={onLocationSelect}
            selectedLocation={selectedLocation}
            selectedPhenoms={selectedPhenoms}
          />

          {/* <GaugeSection
            floorPlanLocationList={floorPlanLocationList}
            selectedLocation={selectedLocation}
            loading={isLoading || loading}
            selectedPhenoms={selectedPhenoms}
          /> */}
        </Col>
      </Row>
    </Fragment>
  );
};

export default FloorPlans;

const onManageFloorplanLocations = (data: FloorPlanLocationsWithPosition[]) => {
  let phenomCollection: string[] = [];
  const keys = Object.keys(airQualityFilterValues);
  if (data && data.length > 0) {
    data = data.map((el) => {
      let tempObj = { ...el };
      if (tempObj?.latestData?.Dnum) {
        const tempData = Object.entries(tempObj.latestData.Dnum);
        const tempSensor = tempData.map(([key, value]) => key.toUpperCase());
        phenomCollection.push(...tempSensor);
      }

      const phenomsData = tempObj?.latestData?.Dnum;
      const sensorData: { [k: string]: number } = {};
      if (phenomsData) {
        for (const [key, value] of Object.entries(tempObj.latestData.Dnum)) {
          sensorData[key.toUpperCase()] = value;
        }
      }
      const sensorList = tempObj?.sensorSpecs;
      let colorCode = '';
      let colorName = '';
      if (sensorData && sensorList && sensorList.length > 0) {
        sensorList.forEach((ph) => {
          const sensorValue = sensorData?.[ph?.shortName?.toUpperCase()];
          if (sensorValue) {
            if (Number(sensorValue) >= Number(ph.redPoint)) {
              colorCode = '#ff5454';
              colorName = keys[0];
            } else if (Number(sensorValue) >= Number(ph.amberPoint)) {
              colorCode = '#efd613';
              colorName = keys[1];
            } else {
              colorCode = '#3dcc5b';
              colorName = keys[2];
            }
          }
        });
      }
      tempObj = { ...tempObj, colorCode, colorName };
      return { ...tempObj };
    });
  }

  return {
    phenomCollection: Array.from(new Set(phenomCollection)),
    floorPlanLocations: data,
  };
};
