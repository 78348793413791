import { FormInputType } from '../../../@types';

export const initFormElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      name: 'userName',
      type: 'text',
      placeholder: 'Enter username',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Username is required',
    label: 'Username',
    disabled: true,
  },
  currentPassword: {
    elementType: 'input',
    elementConfig: {
      name: 'currentPassword',
      type: 'password',
      placeholder: 'Enter Current password',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Current Password is required',
    label: 'Current Password',
  },
  newPassword: {
    elementType: 'input',
    elementConfig: {
      name: 'newPassword',
      type: 'password',
      placeholder: 'Enter New password',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'New Password is required',
    label: 'New Password',
  },
};

export type StateType = {
  formElements: FormElementsType;
  loading: boolean;
};

export type FormElementsType = {
  userName: FormInputType;
  currentPassword: FormInputType;
  newPassword: FormInputType;
};
