export const sharedReducerHookActionTypes = {
  setState: 'SET_STATE',
};

export const aqiColors = [
  {
    value: 1,
    color: '#9CFF9C',
  },
  {
    value: 2,
    color: '#31FF00',
  },
  {
    value: 3,
    color: '#31CF00',
  },
  {
    value: 4,
    color: '#FFFF00',
  },
  {
    value: 5,
    color: '#FFCF00',
  },
  {
    value: 6,
    color: '#FF9A00',
  },
  {
    value: 7,
    color: '#FF6464',
  },
  {
    value: 8,
    color: '#FF0000',
  },
  {
    value: 9,
    color: '#990000',
  },
  {
    value: 10,
    color: '#CE30FF',
  },
];
