import moment from 'moment';
import {
  AxiosErrorResponseType,
  ChartListType,
  GaugeListType,
  ApiResponseDataType,
  DefaultObjectType,
  BooleanObjectType,
} from '../@types';
import { SensorListType } from '../@types/api-types';

export function handleError({
  message,
}: {
  message: string;
}): ApiResponseDataType {
  return { status: 'error', message: message || 'Some error occured!' };
}

export function handleNetworkError(err: any) {
  return !!err.isAxiosError && !err.response;
}

export function handleApiError(error: {
  response: AxiosErrorResponseType<ApiResponseDataType>;
}): AxiosErrorResponseType<ApiResponseDataType> {
  const errorResponse = error?.response;
  if (errorResponse) {
    if (errorResponse.status === 404) {
      return {
        ...errorResponse,
        data: {
          status: 'error',
          message: errorResponse.statusText,
        },
      };
    }
  } else if (handleNetworkError(error)) {
    return {
      data: { status: 'error', message: 'Network Error!' },
    };
  }

  return errorResponse;
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function generateRandomNumber() {
  return Date.now() + Math.floor(Math.random() * 100);
}

export function handleSorting(first: string, second: string) {
  if (first && second) {
    const nameA = first.toUpperCase();
    const nameB = second.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

export function handleTableSearch({
  data,
  columnList,
  searchData,
}: {
  data: any;
  columnList: string[];
  searchData: string;
}) {
  searchData = searchData.replace(/\\/g, '');
  const filteredData = data.filter((item: any) => {
    let temp = null;
    columnList.forEach((el) => {
      if (el && typeof item[el] === 'string') {
        if (item[el].toLowerCase().search(searchData.toLowerCase()) !== -1) {
          temp = item;
        }
      }
    });
    return temp;
  });

  return filteredData;
}

export function getZuluFormatUTC(date?: string, time?: string) {
  let dateTime: any = '';
  if (!date && !time) {
    dateTime = moment().toDate();
  } else if (time && !date) {
    dateTime = `${getLocalCurrentDate()} ${time}`;
  } else if (date && !time) {
    dateTime = `${date} 00:00:00`;
  } else {
    dateTime = `${date} ${time}`;
  }

  // const convertedDate = new Date(dateTime).toISOString();
  // const convertedDate = moment(dateTime).toISOString();
  const convertedDate = moment.utc(dateTime).toISOString();
  return convertedDate;
}

export function getLocalCurrentDate() {
  const today = moment().toDate();
  const year = today.getFullYear().toString();
  let month = today.getMonth().toString();
  let date = today.getDate().toString();
  if (month.length === 1) {
    month = `0${month}`;
  }
  if (date.length === 1) {
    date = `0${date}`;
  }
  return `${year}-${month}-${date}`;
}

export function manageGaugeData({
  phenomList,
  gaugeSensors,
  locationID = '',
  gaugeColor,
}: {
  phenomList: SensorListType[];
  gaugeSensors: { [k: string]: number };
  locationID: string;
  gaugeColor?: string;
}): GaugeListType[] | undefined {
  if (
    phenomList &&
    phenomList.length > 0 &&
    gaugeSensors &&
    Object.keys(gaugeSensors).length > 0
  ) {
    const tempGauges = [];
    for (const key in gaugeSensors) {
      const match = phenomList.find(
        (item) => item.shortName.toUpperCase() === key.toUpperCase()
      );

      let minScale = match?.minScale;
      let maxScale = match?.maxScale;
      if (minScale === undefined || minScale === null) {
        minScale = 0;
      }
      if (maxScale === undefined || maxScale === null || maxScale === 0) {
        maxScale = 100;
      }
      const tempObj = {
        locationID: locationID || '',
        compass: match?.compass ?? false,
        shortName: match?.shortName ?? key,
        longName: match?.longName ?? '',
        graphColour:
          gaugeColor ?? match?.graphColour ?? generateRandomHexCode(),
        minScale,
        maxScale,
        markers: match?.markers ?? [],
        units: match?.units ?? '',
        actualValue: gaugeSensors[key],
        needleValue:
          gaugeSensors[key] > maxScale ? maxScale : gaugeSensors[key],
      };

      tempGauges.push(tempObj);
    }

    return tempGauges;
  }
}

export function manageChartData({
  phenomList,
  locationID,
  locationAveragesList,
  chartColor,
}: {
  phenomList: SensorListType[];
  locationID: string;
  locationAveragesList: { Dnum: BooleanObjectType; TS: string }[];
  chartColor?: string;
}) {
  if (
    phenomList &&
    locationAveragesList &&
    phenomList.length > 0 &&
    locationAveragesList.length > 0
  ) {
    const tempArray: {
      locationID: string;
      shortName: string;
      label: string;
      borderColor: string;
      units: string;
      data: {
        x: string;
        y: boolean;
      }[];
    }[] = [];
    locationAveragesList.forEach((item) => {
      const sensorsValues = getObjectKeysInUpperCase(item.Dnum);
      const timeStamp = item.TS;

      if (timeStamp && sensorsValues && Object.keys(sensorsValues).length > 0) {
        for (const key in sensorsValues) {
          const match = phenomList.find(
            (item) => item.shortName.toUpperCase() === key.toUpperCase()
          );

          const tempObj: {
            locationID: string;
            shortName: string;
            label: string;
            borderColor: string;
            units: string;
            data: {
              x: string;
              y: boolean;
            }[];
          } = {
            locationID,
            shortName: match?.shortName?.toUpperCase?.() ?? key,
            label: match?.longName ? `${match.longName} (${match.units})` : key,
            borderColor:
              chartColor ?? match?.graphColour ?? generateRandomHexCode(),
            units: match?.units ?? '',
            data: [],
          };

          const dataObj = {
            x: moment.utc(timeStamp).format('YYYY-MM-DD HH:mm:ss'),
            y: sensorsValues[key],
          };
          tempObj.data.push(dataObj);

          tempArray.push(tempObj);
        }
      }
    });
    // console.log('tempArray', tempArray);
    const tempArray2: ChartListType[] = tempArray.reduce(
      mergeEquallyLabeledTypes,
      {
        store: {},
        list: [],
      }
    ).list;
    // console.log('tempArray2', tempArray2);
    return tempArray2;
  }
}

function mergeEquallyLabeledTypes(collector: any, type: any) {
  const key = type.shortName; // identity key.
  const store = collector.store;
  const storedType = store[key];
  if (storedType) {
    // merge `children` of identically named types.
    storedType.data = storedType.data.concat(type.data);
  } else {
    store[key] = type;
    collector.list.push(type);
  }
  return collector;
}

export function generateRandomHexCode(): string {
  return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
  // return '#' + Math.floor(Math.random() * 16777215).toString(16);
  // return '#000000'.replace(/0/g, function() {
  //   return (~~(Math.random() * 16)).toString(16);
  // });
}

export function insertAtSpecificArrayIndex(
  arr: any[],
  index: number,
  newItem: any
) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
}

export const getTwoDecimalPoints = (value: number | string) => {
  let isFloat = Number(value) % 1 !== 0;
  if (isFloat) {
    const valueLength = value.toString().split('.')[1].length;

    if (valueLength > 2) {
      value = parseFloat(value.toString()).toFixed(2);
    }
  }

  return Number(value);
};

export const hasObjectKeys = (data: DefaultObjectType): boolean => {
  if (data) {
    return Object.keys(data).length > 0;
  }
  return false;
};

export const hasArrayValues = (data: any[]): boolean => {
  if (data) {
    return data.length > 0;
  }
  return false;
};

export const getObjectKeysInUpperCase = (
  data: DefaultObjectType
): DefaultObjectType => {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k.toUpperCase(), v])
  );
};

export const getObjectKeysInLowerCase = (
  data: DefaultObjectType
): DefaultObjectType => {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k.toLowerCase(), v])
  );
};
