import { Card, Col, Row } from 'antd';
import { Fragment, Component, CSSProperties, MouseEvent } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { FiMinimize } from 'react-icons/fi';
import update from 'immutability-helper';

// import 'chartjs-plugin-zoom';
import { TimeUnit } from 'chart.js';
import { Modal } from 'antd';

import AntdCard from '../../../components-shared/AntdCard';
import { ChartListType } from '../../../@types';

import cssStyles from '../styles/locationDashboard.module.scss';

type PropsType = {
  loading: boolean;
  chartList: ChartListType[];
  switcher: boolean;
  lastHours: string;
};

type StateType = {
  charts: ChartListType[];
  chartType: TimeUnit;
  selectedChart: Partial<ChartListType>;
};

const minMaxIconStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  marginRight: '10px',
  marginTop: '-10px',
  cursor: 'pointer',
};

class Chart extends Component<PropsType, StateType> {
  initCharts: ChartListType[] = [];
  inputRefs: any[] = [];

  constructor(props: PropsType) {
    super(props);

    this.state = { charts: [], chartType: 'hour', selectedChart: {} };
  }

  componentDidMount() {
    this.setChart();
  }

  componentDidUpdate() {
    this.setChart();
  }

  setChart = () => {
    const { charts } = this.state;
    const { chartList, lastHours, switcher } = this.props;

    if (chartList.length !== charts.length) {
      this.initCharts = update(this.initCharts, { $set: chartList });
      let type: TimeUnit = 'hour';
      if (Number(lastHours) > 24 || !switcher) {
        type = 'day';
      }
      this.setState({ charts: chartList, chartType: type });
    }
  };

  onChartZoom = (params: { chart: any }) => {
    // console.log('params', params.chart);
  };

  setRef = (ref: any) => {
    this.inputRefs = update(this.inputRefs, { $push: [ref] });
  };

  onMaxClick = (record: ChartListType) => {
    const { charts } = this.state;

    let temp = charts.filter((el) => el.shortName !== record.shortName);

    record = update(record, { maximize: { $set: true } });

    temp = update(temp, { $unshift: [record] });

    this.setState({ charts: temp });
  };

  onMinClick = (record: ChartListType) => {
    const { charts } = this.state;

    const index = this.initCharts.findIndex(
      (el) => el.shortName === record.shortName
    );

    let temp = charts.filter((el) => el.shortName !== record.shortName);

    record = update(record, { maximize: { $set: false } });

    temp.splice(index, 1, record);

    this.setState({ charts: temp });
  };

  onIconClick = (record: ChartListType) => {
    this.setState((prev) => ({ ...prev, selectedChart: record }));
  };

  onChartModalClose = () => {
    this.setState((prev) => ({ ...prev, selectedChart: {} }));
  };

  render() {
    const { loading, switcher, lastHours } = this.props;
    const { charts, chartType, selectedChart } = this.state;

    return (
      <Fragment>
        <Row className="py-3">
          <Col xs={24}>
            <AntdCard loading={loading} elevate>
              <Row justify="center">
                <Col>
                  {charts.length === 0 && (
                    <Fragment>
                      <span
                        style={{
                          fontSize: '16px',
                          fontStyle: 'italic',
                          color: 'rgb(54, 114, 248)',
                        }}>
                        {switcher
                          ? `No data within the last ${lastHours || 12} hours`
                          : `No recent data`}
                      </span>
                    </Fragment>
                  )}
                </Col>
              </Row>

              <Row className="py-3">
                {charts.length > 0 &&
                  charts.map((el) => {
                    return (
                      <Fragment key={el.shortName}>
                        <Col xs={24} lg={12} className="px-2 py-2">
                          <Card>
                            <FiMinimize
                              size="1.2em"
                              style={minMaxIconStyle}
                              onClick={() => this.onIconClick(el)}
                            />

                            <LineChart
                              ref={this.setRef}
                              data={{ datasets: [el] }}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      type: 'time',
                                      time: {
                                        unit: chartType,
                                      },
                                      // distribution: 'series'
                                    },
                                  ],
                                },
                                responsive: true,
                                // plugins: {
                                //   zoom: {
                                //     pan: {
                                //       enabled: false,
                                //       mode: 'x',
                                //     },
                                //     zoom: {
                                //       enabled: true,
                                //       mode: 'x',
                                //       onZoom: this.onChartZoom,
                                //     },
                                //   },
                                // },
                              }}
                            />
                          </Card>
                        </Col>
                      </Fragment>
                    );
                  })}
              </Row>
            </AntdCard>
          </Col>
        </Row>
        <ChartModal
          visible={!!selectedChart.locationID}
          onCancel={this.onChartModalClose}
          record={selectedChart}
          chartType={chartType}
        />
      </Fragment>
    );
  }
}

export default Chart;

interface ChartPopupPropsType {
  visible: boolean;
  onCancel: (e: MouseEvent<HTMLElement>) => void;
  record: Partial<ChartListType>;
  chartType: TimeUnit;
}

const ChartModal = ({
  visible,
  onCancel,
  record,
  chartType,
}: ChartPopupPropsType) => {
  return (
    <Fragment>
      <Modal
        className={cssStyles.modalChart}
        bodyStyle={{ width: '80vw', height: '80vh' }}
        closable={false}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width="80vw">
        <LineChart
          data={{ datasets: [record] }}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: chartType,
                  },
                  // distribution: 'series'
                },
              ],
            },
            responsive: true,
          }}
        />
      </Modal>
    </Fragment>
  );
};
