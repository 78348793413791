import { ReactNode } from 'react';
import { Card, CardProps } from 'antd';

import cssStyles from './styles/antdCard.module.scss';

type PropsType = CardProps & {
  children: ReactNode;
  elevate?: boolean;
};

function AntdCard({ children, className, elevate, ...rest }: PropsType) {
  return (
    <Card
      {...rest}
      className={`${className ?? ''} ${elevate ? cssStyles.card : ''}`}>
      {children}
    </Card>
  );
}

export default AntdCard;
