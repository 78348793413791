import { Col, ColProps, Row, RowProps } from 'antd/lib/grid';
import { Fragment, ReactNode } from 'react';
import update from 'immutability-helper';

import Input from '../components-shared/AntdInput';
import { FormInputObjectType, FormInputType } from '../@types';

export function getSingleInputElement({
  formElements,
  inputChangedHandler,
  sliceValue,
  colDetails,
  rowDetails,
  classes,
  keyValue,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  colDetails?: ColProps;
  rowDetails?: RowProps;
  classes?: {
    row?: string;
    col?: string;
  };
  keyValue?: number;
}) {
  let formElementsArray: Array<{ id: string; config: FormInputType }> = [];

  for (const key in formElements) {
    formElementsArray = update(formElementsArray, {
      $push: [{ id: key, config: formElements[key] }],
    });
  }

  formElementsArray = formElementsArray.slice(...sliceValue);

  const inputElements = formElementsArray.map((item, idx) => {
    const shouldValidate =
      item.config.validation && Object.keys(item.config.validation).length > 0;

    const lg = colDetails?.lg || item.config.colValue || 24;

    return (
      <Row
        {...rowDetails}
        className={`${classes?.row || ''}`}
        key={keyValue || idx + 1}>
        <Col
          {...colDetails}
          xs={colDetails?.xs || colDetails?.sm || 24}
          sm={colDetails?.sm || 24}
          md={colDetails?.md || 24}
          lg={lg}
          xl={colDetails?.xl || lg}
          xxl={colDetails?.xxl || lg}
          className={`${classes?.col || item.config.colClasses || ''}`}>
          <Input
            {...item.config}
            invalidCheck={!item.config.valid}
            shouldValidate={shouldValidate}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
    );
  });

  return inputElements;
}

export function getMultipleInputElements({
  formElements,
  inputChangedHandler,
  sliceValue,
  optionalElement,
  colDetails,
  rowDetails,
  classes,
  keyValue,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  optionalElement?: ReactNode;
  colDetails?: ColProps;
  rowDetails?: RowProps;
  classes?: {
    row?: string;
    col?: string;
  };
  keyValue?: number;
}) {
  let formElementsArray: Array<{ id: string; config: FormInputType }> = [];

  for (const key in formElements) {
    formElementsArray = update(formElementsArray, {
      $push: [{ id: key, config: formElements[key] }],
    });
  }

  formElementsArray = formElementsArray.slice(...sliceValue);

  const inputElements = (
    <Fragment>
      <Row {...rowDetails} align="middle" className={`${classes?.row || ''}`}>
        {formElementsArray.map((item, idx) => {
          const shouldValidate =
            item.config.validation &&
            Object.keys(item.config.validation).length > 0;

          const lg = colDetails?.lg || item.config.colValue || 12;

          return (
            <Col
              {...colDetails}
              key={keyValue || idx + 1}
              xs={colDetails?.xs || colDetails?.sm || 24}
              sm={colDetails?.sm || 24}
              md={colDetails?.md || 24}
              lg={lg}
              xl={colDetails?.xl || lg}
              xxl={colDetails?.xxl || lg}
              className={`${classes?.col || item.config.colClasses || ''}${
                idx === 1 ? 'pl-lg-2' : ''
              } `}>
              <Input
                {...item.config}
                invalidCheck={!item.config.valid}
                shouldValidate={shouldValidate}
                onInputChanged={inputChangedHandler}
              />
            </Col>
          );
        })}
        {optionalElement || null}
      </Row>
    </Fragment>
  );

  return inputElements;
}
