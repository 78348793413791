import { Component, ErrorInfo, ReactNode } from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';

type PropsType = RouteComponentProps & {
  children: ReactNode;
  // history: RouteComponentProps['history'];
};

type StateType = {
  hasError: boolean;
};

class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): StateType {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  handleRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: `/`,
    });
    if (window?.location?.reload) {
      window.location.reload();
    }
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button
              type="primary"
              onClick={this.handleRedirect}
              htmlType="button">
              Back Home
            </Button>
          }
        />
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
