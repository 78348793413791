import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // import plugin
dayjs.extend(utc);

export function getCurrentUTC() {
  return dayjs.utc();
}

export function getUTCFromDateTime({ date }: { date: string }) {
  return dayjs.utc(date);
}
