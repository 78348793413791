import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import { Fragment, Component, MouseEvent } from 'react';
import update from 'immutability-helper';

import { getSingleInputElement } from '../../utils/get-input-element';
import { checkValidation } from '../../utils/validation';
import { FormElementsType, initFormElements, StateType } from './helpers';
import { message } from 'antd';
import { handleNotification } from '../../utils/notification-handler';
import { apiCall } from '../../api-services/api';
import { authApi } from '../../api-services/api-list';
import { authSuccess } from '../../redux/actions/auth.actions';
import { connect, ConnectedProps } from 'react-redux';
import AntdCoverSpinner from '../AntdCoverSpinner';
import { setInLocal } from '../../utils/manage-storage';

type PropsType = PropsFromRedux & {
  showModal: boolean;
  handleModal: (params?: boolean) => void;
  userName: string;
  userPassword?: string;
};

class ChangePasswordModal extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: {
        ...initFormElements,
        userName: { ...initFormElements.userName, value: props.userName || '' },
        currentPassword: {
          ...initFormElements.currentPassword,
          value: props.userPassword || '',
        },
      },
      loading: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      this.handleState({
        formElements: tempFormElements,
      });
    }
  };

  onFormSubmit = async (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault?.();

    const { formElements } = this.state;
    const { handleModal, authSuccess } = this.props;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;
    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [key]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    this.handleState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (!tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this.handleState({ loading: true });

    let stateData: Partial<StateType> = {};

    try {
      const { url, method, contentType, params } = authApi.getLogin({
        username: tempFormElements.userName.value,
        password: tempFormElements.currentPassword.value,
        newpassword: tempFormElements.newPassword.value,
      });

      const response = await apiCall({
        url,
        method,
        contentType,
        params,
        cancelToken: this.axiosCancelSource.token,
      });

      const result = response?.data;
      stateData.loading = false;

      if (result?.status === 'ok') {
        setInLocal('token', result.data.token);
        authSuccess({ userData: result.data });
        handleModal(true);
      }
      this.handleState({ ...stateData });
    } catch (error) {
      stateData.loading = false;
      this._isMounted && handleNotification('error', error?.data);
      this.handleState({ ...stateData });
    }
  };

  render() {
    const { showModal, handleModal } = this.props;
    const { formElements, loading } = this.state;

    const single = getSingleInputElement({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 3],
    });

    return (
      <Fragment>
        <Modal
          visible={showModal}
          onCancel={() => handleModal()}
          closable={false}
          onOk={this.onFormSubmit}>
          <AntdCoverSpinner active={loading}>{single}</AntdCoverSpinner>
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatch = {
  authSuccess: authSuccess,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChangePasswordModal);
