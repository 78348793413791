import { Fragment } from 'react';
import { Result } from 'antd';

const ErrorPage = () => {
  return (
    <Fragment>
      <Result
        status="warning"
        title="There are some problems with your operation."
      />
    </Fragment>
  );
};

export default ErrorPage;
