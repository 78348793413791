import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import { Fragment, MouseEvent, PureComponent } from 'react';
import update from 'immutability-helper';

import { getSingleInputElement } from '../../../utils/get-input-element';
import { FormElementsType, initFormElements, StateType } from './helpers';
import { checkValidation } from '../../../utils/validation';
import AntdCoverSpinner from '../../../components-shared/AntdCoverSpinner';
import { message } from 'antd';
import { authApi } from '../../../api-services/api-list';
import { apiCall } from '../../../api-services/api';
import { handleNotification } from '../../../utils/notification-handler';

type PropsType = {
  showModal: boolean;
  handleModal: () => void;
};

class ResetPasswordModal extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: {
        ...initFormElements,
      },
      loading: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      this.handleState({
        formElements: tempFormElements,
      });
    }
  };

  onFormSubmit = async (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault?.();

    const { formElements } = this.state;
    const { handleModal } = this.props;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        userName: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    this.handleState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (!tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this.handleState({ loading: true });

    try {
      const { url, method, contentType, params } = authApi.getResetPassword({
        username: tempFormElements.userName.value,
      });
      const response = await apiCall({
        url,
        method,
        params,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;

      if (result?.status === 'ok') {
        this._isMounted &&
          handleNotification(
            'success',
            {
              status: 'ok',
              message: `Your request has been sent, you should receive an email shortly with a temporary password. Please check your spam folder if you do not receive this within 3 minutes`,
            },
            { onClose: handleModal }
          );
      }
    } catch (error) {
      this._isMounted && handleNotification('error', error?.data);
    }

    this.handleState({
      loading: false,
    });
  };

  render() {
    const { showModal, handleModal } = this.props;
    const { formElements, loading } = this.state;

    const single = getSingleInputElement({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 3],
    });

    return (
      <Fragment>
        <Modal
          visible={showModal}
          onCancel={handleModal}
          closable={false}
          onOk={this.onFormSubmit}>
          <AntdCoverSpinner active={loading}>{single}</AntdCoverSpinner>
        </Modal>
      </Fragment>
    );
  }
}

export default ResetPasswordModal;
